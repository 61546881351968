import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createContact, editContact, deleteContact } from 'r_actions/contacts.actions'
import { setMode, setSelected } from 'r_actions/noApi.actions'
import { validateContact } from 'helpers/validator'
import ContactEditorForm from './ContactEditorForm'

const empty = { name: '', mail: '', phone: '', image: null,  office: 1, id: null }


const ContactsEditor = () => {
  const dispatch = useDispatch()

  const { selected } = useSelector(s => s.noApi)
 
  const [data, setData] = useState({})
  const [submitted, setSubmitted] = useState(false)
    
  const { id, image } = data
    
  const onChange = e => { 
    const { name, value, validity } = e.target  
    if (name === 'phone' && ((!data[name].charAt(0) && value==='+') || validity.valid)) return setData(d => ({ ...d, [name]: value }))
    if (name !== 'phone') setData(d => ({ ...d, [name]: value })) 
    dispatch(setSelected({ ...selected, [name]: value }))
  }
  
  useEffect(() => {
    selected && setData(d => ({ ...d, ...selected }))
    !selected && setData(d => ({ ...d, ...empty }))
  }, [selected, image])


  const { validContact, valid } = validateContact(data)

  const cleanStuff = () => {
    setData(empty)
    dispatch(setMode('icon'))
    dispatch(setSelected(null))
    setSubmitted(false)
  }

  const onSubmit = () => {
    setSubmitted(true)
  
    if(validContact) {
      id 
        ? dispatch(editContact({ id, data: { ...data, image } }))
        : dispatch(createContact(data))

      cleanStuff()
    }
  }

  const onDelete = () => {
    dispatch(deleteContact(id))
    cleanStuff()
  }

  
  return <ContactEditorForm
    data={data}
    onChange={onChange}
    onSubmit={onSubmit}
    onDelete={onDelete}
    setSubmitted={setSubmitted}
    valid={valid}
    submitted={submitted}
  />
  
}


export default ContactsEditor
