import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import ControlButtons from './ControlButtons'
import { StyledEditableElement, StyledListInput } from './Styles'
import { createTherapy, editTherapy, deleteTherapy } from 'r_actions/categories.actions'


const TherapyInput = ({ element, edit, setEdit, category, placeholder, viewOnly }) => {

  const dispatch = useDispatch()

  const { id, title } = element
 
  const [name, setName] = useState(title)

  const onSubmitSub = () => {
    if(id==='new') {
      dispatch(createTherapy({ category, title: name }))
      return setName('')
    }
    dispatch(editTherapy({ id, data: { category, title: name } }))
  }

  const onDeleteSub = () => {
    if(id==='new') return 
    dispatch(deleteTherapy( id ))
  }
 
  useEffect(() => () => setEdit(null), [setEdit])
 

  return <StyledEditableElement className="panel-block" onClick={() => setEdit(id)}>

    <StyledListInput 
      className="input" 
      placeholder={placeholder}
      type="text" 
      value={name} 
      onChange={e => setName(e.target.value)}
      style={{ pointerEvents: viewOnly ? 'none' : 'all' }}
    />
 
    {
      (edit && !viewOnly) && 
      <>
        <ControlButtons disabled={!name || title===name} onSubmit={onSubmitSub} onDelete={onDeleteSub} />
      </>
    }
  
  </StyledEditableElement>
        
}

TherapyInput.propTypes = {
  edit: PropTypes.bool,
  setEdit: PropTypes.func,
  category: PropTypes.number,
  placeholder: PropTypes.string,
  viewOnly: PropTypes.bool,
}

export default TherapyInput

