import React from 'react' 
import { Switch, Redirect, Route } from 'react-router-dom'
import { Admins, Users, Properties/*, Orders, Patients*/ } from 'components/_entities'


const routesAdmin =   

 <Switch>
   <Redirect exact from="/" to="/users" component={Users} />
   <Route path="/admins" component={Admins} />
   <Route path="/users" component={Users} />
   <Route path="/properties" component={Properties} />
   {/* <Route path="/orders" component={Orders} />
   <Route path="/patients" component={Patients} /> */}

   
 </Switch>


export default routesAdmin