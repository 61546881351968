import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useSpring, config, useChain } from 'react-spring'
import { StyledInfoPanel, StyledSemiLink } from '../Styles'
import { Scrollbars } from 'react-custom-scrollbars'

const OrderBar = ({ order, open }) => {

  const { userObject, patientObject, docs, careBoxObject } = order

  const { mail, phone } = userObject
  const { insuranceNumber, birthDate } = patientObject
  const { title, contents } = careBoxObject

  const stopProp = e => e.stopPropagation()

  const onDoc = (e, path) => {
    stopProp(e)
    const newWindow = window.open(path, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }



  const dynamicHeight = docs.length ? docs.length * 20 : 0

  const springRef = useRef()

  const { minHeight, transform, opacity } = useSpring({
    ref: springRef,
    config: config.stiff,
    from: { minHeight: '0px', transform: 'translate3d(0,-50px,0)', opacity: 0 },
    to: { minHeight: open ? `${110 + dynamicHeight}px` : '0px', transform: `translate3d(0,${open ? 0 : -50}px,0)`, opacity: open ? 1 : 0  }
  })

  useChain([springRef], [0, 0])

  const isBox = !!careBoxObject.id

  return <StyledInfoPanel className="columns" style={{ minHeight, transform, opacity }}>

    <div className="column is-1"/>

    <div className={`column ${isBox ? '' : 'is-6'}`}> 
      { open &&
        <>
          <div><b>Dokumente:</b></div>
          {docs.length 
            ? docs.map(d => 
              <div key={d.id}><StyledSemiLink onClick={e => onDoc(e, d.path)}>{d.name}</StyledSemiLink></div>
            )
            : 'keine hinterlegt'
          }
        </>
      }
    </div>
 
    { isBox &&
      <div className="column is-4" onClick={stopProp}>

        <Scrollbars style={{ height: '100%' }}>
          <div><b>Titel:</b> {title}</div>
          <div id="boxContents" style={{ textAlign: 'justify' }}><b>Inhalt:</b> {contents} </div>
        </Scrollbars>

      </div>
    }

    <div className="column">
      <div><b>E-Mail:</b> {mail}</div>
      <div><b>Rufnummer:</b> {phone}</div>
    </div>

    <div className="column">
      <div><b>Geburtsdatum:</b> {birthDate}</div>
      <div><b>Versicherungsnummer:</b> {insuranceNumber}</div>

    </div>

    <div className="column is-1"/>

  </StyledInfoPanel>
   
}

OrderBar.propTypes = {
  order: PropTypes.object, 
  open: PropTypes.bool,

}


export default OrderBar


