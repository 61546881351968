import styled from 'styled-components'

const File = styled('div')`
  height: 60vh;
  overflow: hidden;
  margin-bottom: 10px;

  @media (max-height: 1080px) {
    max-height: 60vh;
  }
`
const Viewer = styled('div')`
  overflow: hidden;
  padding-bottom: 1rem;

  @media (max-height: 1080px) {
    max-height: 60vh;
  }

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 90% !important;
    height: auto !important;
  }
`

const Navigation = styled('div')``

const Title = styled('button')`
  box-shadow: none !important;
  pointer-events: none;
  border-radius: 0;
  outline: none;
`

const Button = styled('button')`
  box-shadow: none;
  border-radius: 0;
  &:focus,
  &:active{
    box-shadow: none !important;
  }
`

export { File, Viewer, Navigation, Title, Button }
