import React , { useEffect }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionHandler from 'components/WorkSpace/SectionHandler'
import { fetchAdvisors } from 'r_actions/advisor.actions'

import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const Advisors = () => {
  const dispatch = useDispatch()

  const advisors = useSelector(s => s.advisors) || []

  useEffect(() => {
    dispatch(fetchAdvisors())
  }, [dispatch])

  return advisors && <SectionHandler list={advisors} />
}


export default Advisors
