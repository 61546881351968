import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import ReactTooltip from 'react-tooltip'

const ToolTip = ({ textId }) => {


  return <ReactTooltip 
    backgroundColor="#292828cc"
  />

}

ToolTip.propTypes = {
  text: PropTypes.string,

}

export default injectIntl(ToolTip)


