import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { createRecipient, editRecipient, deleteRecipient } from 'r_actions/recipients.actions'
import { setMode, setSelected } from 'r_actions/noApi.actions'
import RecipientsEditorForm from './RecipientsEditorForm'
import { validateEmail } from 'helpers/validator'
import toast from 'helpers/toast'

const empty = {  id: null, mail: null }


const RecipientEditor = ({ intl }) => {

  const dispatch = useDispatch()

  const { selected } = useSelector(s => s.noApi)
  const categories = useSelector(s => s.categories) || []

  const [data, setData] = useState({})
  const [submitted, setSubmitted] = useState(false)
    
  const { id, mail } = data

  const validMail = validateEmail(mail)

    
  const onChange = e => { 
    let { name, value } = e.target    
    setData(d => ({ ...d, [name]: value })) 
  }

  useEffect(() => {
    selected && setData(d => ({ ...d, ...selected }))
    !selected && setData(d => ({ ...d, ...empty }))
  }, [selected])
  
  const cleanStuff = () => {
    setData(empty)
    dispatch(setMode('icon'))
    dispatch(setSelected(null))
    setSubmitted(false)
  }
 
  const onSubmit = () => {
    setSubmitted(true)
    
    if(validMail) {
      id 
        ? dispatch(editRecipient({ id, data }))
        : dispatch(createRecipient(data))
      cleanStuff()
    }
 
  }

  const onDelete = () => {
    const category = categories.find(c => +c.recipient === +id)
    if(!!category) return toast('error', intl.formatMessage({ id: 'categories_recipient_used' }, { category: category.title }))

    dispatch(deleteRecipient(id))
    cleanStuff()
  }

  return <RecipientsEditorForm 
    submitted={submitted}
    data={data}
    onChange={onChange}
    onSubmit={onSubmit}
    onDelete={onDelete}
    setSubmitted={setSubmitted}
    valid={validMail}
  />
    
}

export default injectIntl(RecipientEditor)
