// import { animated } from 'react-spring'
import styled from 'styled-components'

const GlassColumn = styled.div`
  width: 100%;
  margin-top: 30px;
  min-height: 90vh;
  max-height: 90vh;
`

const GlassList = styled.div`
  padding: 0;
  position: relative;
  background: white;
  margin: 20px;
  user-select: none;
`

const GlassViewer = styled.div`
  position: relative;
  background: white;
  margin: 20px;
  width: 100%;
  overflow: hidden;
`

const StyledButtonSection = styled.div`
  position: absolute;
  left: 12px;
  bottom: 8px;
  width: 100%;
  padding: 12px;
`

export {
  GlassColumn,
  GlassList,
  GlassViewer,
  StyledButtonSection,
  
}
