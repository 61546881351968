import initialState from './initialState'
import { 
  FETCH_DISEASES_SUCCESS, FETCH_DISEASES_FAILURE,
  CREATE_DISEASES_SUCCESS, CREATE_DISEASES_FAILURE,
  EDIT_DISEASES_SUCCESS, EDIT_DISEASES_FAILURE,
  DELETE_DISEASES_SUCCESS, DELETE_DISEASES_FAILURE
} from 'r_constants/diseases.constants'

export default function diseases(diseases = initialState.diseases, action) {
  switch (action.type) {
      
  case FETCH_DISEASES_SUCCESS:
    return formDiseases(action.diseases)

  case FETCH_DISEASES_FAILURE:
    return diseases

  case CREATE_DISEASES_SUCCESS:
    return formDiseases(diseases, action.disease)

  case CREATE_DISEASES_FAILURE:
    return diseases

  case EDIT_DISEASES_SUCCESS:
    return diseases.map(c => 
      +action.disease.id === +c.id 
        ? { ...action.disease, nameValue: action.disease.title }
        : c
    )
  
  case EDIT_DISEASES_FAILURE:
    return diseases

  case DELETE_DISEASES_SUCCESS:
    return diseases.filter(c => +c.id !== +action.disease.id )

  case DELETE_DISEASES_FAILURE:
    return diseases

  default:
    return diseases
  }
}


const formDiseases = (diseases, disease) => {
  const fullDiseases = disease ? [...diseases, disease] : diseases
  return diseases
    ? fullDiseases.reduce((acc, c) => [...acc, { ...c, nameValue: c.title }]
      , [])
    : null
}