import initialState from './initialState'
import { 
  FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE,
  CREATE_USERS_SUCCESS, CREATE_USERS_FAILURE,
  EDIT_USERS_SUCCESS, EDIT_USERS_FAILURE,
  DELETE_USERS_SUCCESS, DELETE_USERS_FAILURE

} from 'r_constants/users.constants'

const USER = 3

export default function users(users = initialState.users, action) {
  switch (action.type) {
      
  case FETCH_USERS_SUCCESS:

    return formUsers(action.users)

  case FETCH_USERS_FAILURE:
    return users

  case CREATE_USERS_SUCCESS:
    return formUsers(users, action.user)

  case CREATE_USERS_FAILURE:
    return users

  case EDIT_USERS_SUCCESS:
    return users.map(u => 
      +action.user.id === +u.id
        ? { ...action.user, 
          nameValue: action.user.name && action.user.surname 
            ? `${action.user.surname} ${action.user.name}` 
            : action.user.username 
        }
        : u
    )

  case EDIT_USERS_FAILURE:
    return users

  case DELETE_USERS_SUCCESS:
    return users.filter(u => +u.id !== +action.user.id )

  case DELETE_USERS_FAILURE:
    return users

  default:
    return users
  }
}


const formUsers = (users, user) => {
  const fullUsers = user ? [...users, user] : users
  return users 
    ? fullUsers.reduce((acc, u) => +u.role === USER 
      ? [...acc, { ...u, 
        nameValue: u.name && u.surname ? `${u.surname} ${u.name}` : u.username,
      }]
      : acc
    , [])
    : null
  
}

//! { jam: 1, admin: 2 }
