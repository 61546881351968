import React from 'react'
import { injectIntl } from 'react-intl'

class CurrentLocale extends React.Component {

  componentDidMount() {
    if (!CurrentLocale.instance)
      CurrentLocale.instance = this
  }

  render() {
    return false
  }
}

export default injectIntl(CurrentLocale)

export const intl = () => CurrentLocale.instance.props.intl
export const formatMessage = (...args) => intl().formatMessage(...args)
