import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createBox, editBox, deleteBox } from 'r_actions/boxes.actions'
import { setMode, setSelected } from 'r_actions/noApi.actions'
import BoxEditorForm from './BoxEditorForm'

const empty = {  id: null, title: '', contents: '' }


const BoxEditor = () => {

  const dispatch = useDispatch()

  const { selected } = useSelector(s => s.noApi)

  const [data, setData] = useState({})
  const [submitted, setSubmitted] = useState(false)
    
  const { id, title, contents } = data

    
  const onChange = e => { 
    const { name, value } = e.target     
    setData(d => ({ ...d, [name]: value })) 
  }
  
  useEffect(() => {
    selected && setData(d => ({ ...d, ...selected }))
    !selected && setData(d => ({ ...d, ...empty }))
  }, [selected])
  
  const cleanStuff = () => {
    setData(empty)
    dispatch(setMode('icon'))
    dispatch(setSelected(null))
    setSubmitted(false)
  }
 
  const onSubmit = () => {
    setSubmitted(true)
    
    if(!!title && !!contents) {
      id 
        ? dispatch(editBox({ id, data }))
        : dispatch(createBox(data))
      cleanStuff()
    }
  }

  const onDelete = () => {
    dispatch(deleteBox(id))
    cleanStuff()
  }

  return <BoxEditorForm 
    submitted={submitted}
    data={data}
    onChange={onChange}
    onSubmit={onSubmit}
    onDelete={onDelete}
    setSubmitted={setSubmitted}
  />
    

}


export default BoxEditor
