import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { ChevronDown } from 'react-feather'
import toast from 'helpers/toast'
import { SytledUpperWrapper, StyledWrappper, StyledElement, StyledTrigger, StyledMenu } from './Styles'

const Dropdown = ({ intl, options, property, value, type, onChange, placeholder }) => {

  const [active, setActive] = useState(false)

  const { mode } = useSelector(s => s.noApi)

  const handleSelection = (o) =>  onChange({ target: { name: type, value: o.id } })
  
  const onSetActive = () => {
    if (mode === 'view') return toast('error', intl.formatMessage({ id: 'select_on_edit_mode_only' }))
    setActive(!active)
  }

  const selected = options.find(o => +o.id === +value)


  return (
    <SytledUpperWrapper>
      <StyledWrappper className={`dropdown  is-fullwidth ${active ? 'is-active' : ''}`} onClick={onSetActive} onBlur={() => setActive('')}>

        <StyledTrigger className="dropdown-trigger">
          <button className="button is-fullwidth" aria-haspopup="true" aria-controls="dropdown-menu">
            <span>{value 
              ? selected[property]
              : placeholder}
            </span>
            <span className="icon is-small">
              <ChevronDown/>
            </span>
          </button>
        </StyledTrigger>

        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <StyledMenu className="dropdown-content">
  
            {options.map((o) => 

              <StyledElement 
                key={o.id} 
                className="dropdown-item" 
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleSelection(o)}
              >
                {o[property]}
              </StyledElement>

            )}
  
          </StyledMenu>
        </div>

      </StyledWrappper>

      {value && <p className="help">{ placeholder }</p>}

    </SytledUpperWrapper>
  )
}

Dropdown.propTypes = {
  options: PropTypes.array,
  property: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

export default injectIntl(Dropdown)

