import React from 'react'
import PropTypes from 'prop-types'
import { StyledInfoWrapper } from '../Styles'
import BasicContent from './BasicContent'
import AdvancedContent from './AdvancedContent'
import ToolTip from 'components/common/ToolTip'

const OrderBar = ({ order, onOpen, open }) => {

  const { id } = order

  return <StyledInfoWrapper  onClick={() => onOpen(id)} >

    <ToolTip />

    <BasicContent order={order} open={open}/>
    
    <AdvancedContent order={order} open={open}/>
      
  </StyledInfoWrapper>

}

OrderBar.propTypes = {
  order: PropTypes.object, 
  onOpen: PropTypes.func,
  open: PropTypes.bool,

}


export default OrderBar


