import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Scrollbars } from 'react-custom-scrollbars'
import { StyledWrapper, StyledHeader, StyledList } from './Styles'
import OrderBar from './bar/OrderBar'

const OrdersEditor = ({ intl, orders }) => {

  const [open, setOpen] = useState([])

  const handleOpen = (id) => {
    
    const newOpen = open.includes(id)
      ? open.filter(o => +o !== +id)
      : [...open, id]

    setOpen(newOpen)
  }
      
  const titleArray = Array.apply(null, Array(5)).map((x, i) => i)

  return orders && <StyledWrapper>

    <StyledHeader className="columns">

      <div className="column is-1"/>
    
      {titleArray.map(t => 
        <div className="column" key={t}>
          {intl.formatMessage({ id: `orders_header_${t}` })}
        </div>
      )}
      
      <div className="column is-1"/>

    </StyledHeader>
   

    <Scrollbars style={{ height: '85vh' }}>
      <StyledList>

        {
          orders.map(o => <OrderBar 
            key={o.id} 
            order={o} 
            open={!!open.find(f => +f === +o.id)} 
            onOpen={handleOpen}
          />
          )
        }

      </StyledList>
    </Scrollbars>


  </StyledWrapper>
}

OrdersEditor.propTypes = {
  orders: PropTypes.array, 

}

export default injectIntl(OrdersEditor)


