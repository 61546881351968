import { PROPERTIES, AREAS, SUB_AREAS } from './feathers'

export const _fetchProperties = async () => {
  const { data } = await PROPERTIES.find()
  return data
}

export const _createProperty = async (_property) => {
  const property = await PROPERTIES.create(_property)
  return property
}

export const _editProperty = async ({ id, data }) => {
  const property = await PROPERTIES.patch(id, data)
  return property
}

export const _deleteProperty = async (id) => {
  const property = await PROPERTIES.remove(id)
  return property
}


export const _createArea = async (_area) => {
  const area = await AREAS.create(_area)
  return area
}

export const _editArea = async ({ id, data }) => {
  const area = await AREAS.patch(id, data)
  return area
}

export const _deleteArea = async (id) => {
  const area = await AREAS.remove(id)
  return area
}


export const _createSubArea = async (_subArea) => {
  const subArea = await SUB_AREAS.create(_subArea)
  return subArea
}

export const _editSubArea = async ({ id, data }) => {
  const subArea = await SUB_AREAS.patch(id, data)
  return subArea
}

export const _deleteSubArea = async (id) => {
  const subArea = await SUB_AREAS.remove(id)
  return subArea
}