export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS'
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE'

export const CREATE_CONTACTS_SUCCESS = 'CREATE_CONTACTS_SUCCESS'
export const CREATE_CONTACTS_FAILURE = 'CREATE_CONTACTS_FAILURE'

export const EDIT_CONTACTS_SUCCESS = 'EDIT_CONTACTS_SUCCESS'
export const EDIT_CONTACTS_FAILURE = 'EDIT_CONTACTS_FAILURE'

export const DELETE_CONTACTS_SUCCESS = 'DELETE_CONTACTS_SUCCESS'
export const DELETE_CONTACTS_FAILURE = 'DELETE_CONTACTS_FAILURE'



  

