import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { StyledButtonSection } from '../Styles'
import Submit from './Submit'
import Edit from './Edit'

const EditorButtons = ({ onSubmit, reset, canDelete, onDelete }) => {

  const { mode } = useSelector((s) => s.noApi)

  return  <>
  
    { mode !== 'icon' &&  

    <StyledButtonSection className="columns">

      {mode === 'view' && <Edit canDelete={canDelete} onDelete={onDelete}/>}
      {mode === 'edit' && <Submit onSubmit={onSubmit} reset={reset} />}
     
    </StyledButtonSection>

    }
  </>
  
}

EditorButtons.propTypes = {
  onSubmit: PropTypes.func,
  reset: PropTypes.func,
}

export default EditorButtons
