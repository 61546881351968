import { 
  FETCH_RECIPIENTS_SUCCESS, FETCH_RECIPIENTS_FAILURE,
  CREATE_RECIPIENTS_SUCCESS, CREATE_RECIPIENTS_FAILURE,
  EDIT_RECIPIENTS_SUCCESS, EDIT_RECIPIENTS_FAILURE,
  DELETE_RECIPIENTS_SUCCESS, DELETE_RECIPIENTS_FAILURE
} from 'r_constants/recipients.constants'
      
import { _fetchRecipients, _createRecipient, _editRecipient, _deleteRecipient } from 'r_services/recipients.service'
      
export const fetchRecipients = () => async dispatch => {
  try {
    const recipients = await _fetchRecipients()
    dispatch({ type: FETCH_RECIPIENTS_SUCCESS, recipients })
  } catch (error) {
    dispatch({ type: FETCH_RECIPIENTS_FAILURE, error })
  }
}
      
export const createRecipient = (_recipient) => async dispatch => {
  try {
    const recipient = await _createRecipient(_recipient)
    dispatch({ type: CREATE_RECIPIENTS_SUCCESS, recipient })
  } catch (error) {
    dispatch({ type: CREATE_RECIPIENTS_FAILURE, error })
  }
}
      
export const editRecipient = (_recipient) => async dispatch => {
  try {
    const recipient = await _editRecipient(_recipient)
    dispatch({ type: EDIT_RECIPIENTS_SUCCESS, recipient })
  } catch (error) {
    dispatch({ type: EDIT_RECIPIENTS_FAILURE, error })
  }
}
      
export const deleteRecipient = (id) => async dispatch => {
  try {
    const recipient = await _deleteRecipient(id)
    dispatch({ type: DELETE_RECIPIENTS_SUCCESS, recipient })
  } catch (error) {
    dispatch({ type: DELETE_RECIPIENTS_FAILURE, error })
  }
}
      
      
      