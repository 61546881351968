import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton,  } from './Styles'
import { Check, Trash2 } from 'react-feather'

const ListInput = ({ disabled, onSubmit, onDelete }) => {

  return <>

    <StyledButton className="button is-primary" disabled={disabled} onClick={onSubmit}>
      <Check size={18} color="#ffff" />
    </StyledButton>

    <StyledButton className="button is-danger " onClick={onDelete}>
      <Trash2 size={18} color="#ffff" />
    </StyledButton>
        
  </>

        
}

ListInput.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
}

export default ListInput

