import { 
  FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE,
  CREATE_CATEGORIES_SUCCESS, CREATE_CATEGORIES_FAILURE,
  EDIT_CATEGORIES_SUCCESS, EDIT_CATEGORIES_FAILURE,
  DELETE_CATEGORIES_SUCCESS, DELETE_CATEGORIES_FAILURE,
  CREATE_THERAPIES_SUCCESS, CREATE_THERAPIES_FAILURE,
  EDIT_THERAPIES_SUCCESS, EDIT_THERAPIES_FAILURE,
  DELETE_THERAPIES_SUCCESS, DELETE_THERAPIES_FAILURE,
} from 'r_constants/categories.constants'
      
import { _fetchCategories, _createCategory, _editCategory, _deleteCategory, _createTherapy, _editTherapy, _deleteTherapy } from 'r_services/categories.service'
      
export const fetchCategories = () => async dispatch => {
  try {
    const categories = await _fetchCategories()
    dispatch({ type: FETCH_CATEGORIES_SUCCESS, categories })
  } catch (error) {
    dispatch({ type: FETCH_CATEGORIES_FAILURE, error })
  }
}
      
export const createCategory = (_category) => async dispatch => {
  try {
    const category = await _createCategory(_category)
    dispatch({ type: CREATE_CATEGORIES_SUCCESS, category })
  } catch (error) {
    dispatch({ type: CREATE_CATEGORIES_FAILURE, error })
  }
}
      
export const editCategory = (_category) => async dispatch => {
  try {
    const category = await _editCategory(_category)
    dispatch({ type: EDIT_CATEGORIES_SUCCESS, category })
  } catch (error) {
    dispatch({ type: EDIT_CATEGORIES_FAILURE, error })
  }
}
      
export const deleteCategory = (id) => async dispatch => {
  try {
    const category = await _deleteCategory(id)
    dispatch({ type: DELETE_CATEGORIES_SUCCESS, category })
  } catch (error) {
    dispatch({ type: DELETE_CATEGORIES_FAILURE, error })
  }
}



export const createTherapy = (_therapy) => async dispatch => {
  try {
    const therapy = await _createTherapy(_therapy)
    dispatch({ type: CREATE_THERAPIES_SUCCESS, therapy })
  } catch (error) {
    dispatch({ type: CREATE_THERAPIES_FAILURE, error })
  }
}
      
export const editTherapy = (_therapy) => async dispatch => {
  try {
    const therapy = await _editTherapy(_therapy)
    dispatch({ type: EDIT_THERAPIES_SUCCESS, therapy })
  } catch (error) {
    dispatch({ type: EDIT_THERAPIES_FAILURE, error })
  }
}
      
export const deleteTherapy = (id) => async dispatch => {
  try {
    const therapy = await _deleteTherapy(id)
    dispatch({ type: DELETE_THERAPIES_SUCCESS, therapy })
  } catch (error) {
    dispatch({ type: DELETE_THERAPIES_FAILURE, error })
  }
}
      
      
      