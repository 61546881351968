import styled from 'styled-components'

const StyledUserButton = styled.div`
  height: 3rem;
  min-width: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 22px;
`

const StyledUserMenu = styled.div`
  width: 100%;
`
const StyledDropDownWrapper = styled.div`
  user-select: none;
`
const StyledMenuElement = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 20px;
  &:hover {
    background-color: #ededed;
  }
  div:first-child {
    margin: 0px 10px 0px 0px;
  }
`

const StyledSub = styled.div`
  text-align: center;
  vertical-align: middle;
  line-height: 18px;
  margin: 0 2px;
`

const StyledSubNav = styled.div`
  text-align: center;
  vertical-align: middle;
  line-height: 18px;
  margin: 0 2px;
  font-size: 18px;
`

export {
  StyledUserButton,
  StyledUserMenu,
  StyledDropDownWrapper,
  StyledMenuElement,
  StyledSub,
  StyledSubNav,
}
