import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { AtSign } from 'react-feather'
import EditorButtons from 'components/WorkSpace/editorButtons/EditorButtons'
import { StyledColumn } from 'Styles'

import Input from 'components/common/input/Input'


const ContactEditorForm = ({ intl, data, onChange, onSubmit, onDelete, valid, setSubmitted, submitted }) => {

  const { mail } = data
  
  //TODO: addicon 
  const { mode } = useSelector(s => s.noApi)

  const viewOnly = mode === 'view' ? true : false
  
  return mode === 'icon' 

    ? <AtSign size={48} color="#949494"/>

    : <div className="columns">

      <StyledColumn className="column">
         
        
        <Input
          placeholder={intl.formatMessage({ id: 'contacts_mail' })}
          type="mail"
          value={mail}
          isValid={!submitted || valid} 
          onChange={onChange}
          invalidText={!!mail
            ? intl.formatMessage({ id: 'input-mail_invalid' })
            : intl.formatMessage({ id: 'input-mail' })
          }
          viewOnly={viewOnly}
        />   
                
   
      </StyledColumn>

      <StyledColumn className="column" />
     
      <EditorButtons onSubmit={onSubmit} canDelete={true} onDelete={onDelete} reset={() => setSubmitted(false)}/>
    </div>

  
}

ContactEditorForm.propTypes = {
  data: PropTypes.object, 
  onChange: PropTypes.func, 
  checkName: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  valid: PropTypes.bool,
  setSubmitted: PropTypes.func,
}


export default injectIntl(ContactEditorForm)
