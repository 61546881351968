import { 
  FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE,
  CREATE_USERS_SUCCESS, CREATE_USERS_FAILURE,
  CHECK_USERNAME_SUCCESS, CHECK_USERNAME_FAILURE,
  EDIT_USERS_SUCCESS, EDIT_USERS_FAILURE,
  DELETE_USERS_SUCCESS, DELETE_USERS_FAILURE,
  RESET_USERNAME_CHECK
} from 'r_constants/users.constants'
  
import { _fetchUsers, _createUser, _checkUsername, _editUser, _deleteUser } from 'r_services/users.service'
  
export const fetchUsers = () => async dispatch => {
  try {
    const users = await _fetchUsers()
    dispatch({ type: FETCH_USERS_SUCCESS, users })
  } catch (error) {
    dispatch({ type: FETCH_USERS_FAILURE, error })
  }
}

export const createUser = (_user) => async dispatch => {
  try {
    const user = await _createUser(_user)
    dispatch({ type: CREATE_USERS_SUCCESS, user })
  } catch (error) {
    dispatch({ type: CREATE_USERS_FAILURE, error })
  }
}

export const editUser = (_user) => async dispatch => {
  try {
    const user = await _editUser(_user)
    dispatch({ type: EDIT_USERS_SUCCESS, user })
  } catch (error) {
    dispatch({ type: EDIT_USERS_FAILURE, error })
  }
}

export const deleteUser = (id) => async dispatch => {
  try {
    const user = await _deleteUser(id)
    dispatch({ type: DELETE_USERS_SUCCESS, user })
  } catch (error) {
    dispatch({ type: DELETE_USERS_FAILURE, error })
  }
}


export const checkUsername = (name) => async dispatch => {
  try {
    const valid = await _checkUsername(name)
    dispatch({ type: CHECK_USERNAME_SUCCESS, valid })
  } catch (error) {
    dispatch({ type: CHECK_USERNAME_FAILURE, error })
  }
}
  
export const resetUsernameCheck = () => async dispatch => {
  dispatch({ type: RESET_USERNAME_CHECK })

}
  
