import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import feathersAPI from 'helpers/api_config'
import initialState from './initialState'
import { 
  FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAILURE,
} from 'r_constants/orders.constants'

export default function orders(orders = initialState.orders, action) {
  switch (action.type) {
      
  case FETCH_ORDERS_SUCCESS:
    return formOrders(action.orders)

  case FETCH_ORDERS_FAILURE:
    return orders

  
  default:
    return orders
  }
}

const formDate = (date, form) => format(new Date(date), form, { locale: de })


const formOrders = (orders, order) => {
  const fullOrders = order ? [...orders, order] : orders
  return orders
    ? fullOrders.reduce((acc, c) => {
      let { patientObject: { birthDate } } = c
      birthDate = birthDate ? formDate(birthDate, 'dd.MM.yyyy') : 'nicht angegeben' 
      return [...acc, { ...c, 
        nameValue: c.title, 
        date: formDate(c.date, 'dd.MM.yyyy HH:mm'),
        docs: c.docs.map(d => ({ ...d, 
          path: `${feathersAPI}/api/uploads/${d.path}`,
          name: /[^/]*$/.exec(d.path)[0]
        })),
        patientObject: { ...c.patientObject, birthDate },
      }].sort((a,b) => b.id - a.id)}
    , [])
    : null
}