import initialState from './initialState'
import { LOGIN_SUCCESS, LOGIN_FAILURE, 
  LOGOUT_SUCCESS, LOGOUT_FAILURE, 
  AUTH_SUCCESS, AUTH_ERROR
} from 'r_constants/auth.constants'



export default function authentification(auth = initialState.auth, action) {
  switch (action.type) {
      
  case LOGIN_SUCCESS:
  case  AUTH_SUCCESS:
    return action.payload || null

  case LOGIN_FAILURE:
  case AUTH_ERROR:
    return  auth

  case LOGOUT_SUCCESS:
    return null

  case LOGOUT_FAILURE:
    return auth


  default:
    return auth
  }
}


