import initialState from './initialState'
import { 
  FETCH_BOXES_SUCCESS, FETCH_BOXES_FAILURE,
  CREATE_BOXES_SUCCESS, CREATE_BOXES_FAILURE,
  EDIT_BOXES_SUCCESS, EDIT_BOXES_FAILURE,
  DELETE_BOXES_SUCCESS, DELETE_BOXES_FAILURE
} from 'r_constants/boxes.constants'

export default function boxes(boxes = initialState.boxes, action) {
  switch (action.type) {
      
  case FETCH_BOXES_SUCCESS:
    return formBoxes(action.boxes)

  case FETCH_BOXES_FAILURE:
    return boxes

  case CREATE_BOXES_SUCCESS:
    return formBoxes(boxes, action.box)

  case CREATE_BOXES_FAILURE:
    return boxes

  case EDIT_BOXES_SUCCESS:
    return boxes.map(c => 
      +action.box.id === +c.id 
        ? { ...action.box, nameValue: action.box.title }
        : c
    )
  
  case EDIT_BOXES_FAILURE:
    return boxes

  case DELETE_BOXES_SUCCESS:
    return boxes.filter(c => +c.id !== +action.box.id )

  case DELETE_BOXES_FAILURE:
    return boxes

  default:
    return boxes
  }
}


const formBoxes = (boxes, box) => {
  const fullBoxes = box ? [...boxes, box] : boxes
  return boxes
    ? fullBoxes.reduce((acc, c) => [...acc, { ...c, nameValue: c.title }]
      , [])
    : null
}