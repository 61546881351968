import React from 'react' 
import { Switch, Redirect, Route } from 'react-router-dom'
import { Admins, Boxes, Diseases, Clients, Contacts, Orders, Patients, Advisors, Categories, Recipients } from 'components/_entities'


const routesJam =

  <Switch>
    <Redirect exact from="/" to="/admins" component={Admins} />
    <Route path="/admins" component={Admins} />
    <Route path="/boxes" component={Boxes} />
    <Route path="/diseases" component={Diseases} />
    <Route path="/clients" component={Clients} />
    <Route path="/contacts" component={Contacts} />
    <Route path="/orders" component={Orders} />
    {/* <Route path="/patients" component={Patients} /> */}
    <Route path="/advisors" component={Advisors} />
    <Route path="/categories" component={Categories} />
    <Route path="/recipients" component={Recipients} />

  </Switch>



export default routesJam