import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionHandler from 'components/WorkSpace/SectionHandler'
import { fetchUsers } from 'r_actions/users.actions'
import { fetchClients } from 'r_actions/clients.actions'


const Admins = () => {
  const dispatch = useDispatch()

  const admins = useSelector(s => s.admins) || []

  useEffect(() => {
    dispatch(fetchUsers())
    dispatch(fetchClients())
  }, [dispatch])

  return admins && <SectionHandler list={admins} />
}
//TODO: loader

Admins.propTypes = {}

export default Admins
