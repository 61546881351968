import initialState from './initialState'
import { 
  FETCH_ADVISORS_SUCCESS, FETCH_ADVISORS_FAILURE,
  CREATE_ADVISORS_SUCCESS, CREATE_ADVISORS_FAILURE,
  EDIT_ADVISORS_SUCCESS, EDIT_ADVISORS_FAILURE,
  DELETE_ADVISORS_SUCCESS, DELETE_ADVISORS_FAILURE
} from 'r_constants/advisor.constants'

export default function advisors(advisors = initialState.advisors, action) {
  switch (action.type) {
      
  case FETCH_ADVISORS_SUCCESS:
    return formAdvisors(action.advisors)

  case FETCH_ADVISORS_FAILURE:
    return advisors

  case CREATE_ADVISORS_SUCCESS:
    return formAdvisors(advisors, action.advisor)

  case CREATE_ADVISORS_FAILURE:
    return advisors

  case EDIT_ADVISORS_SUCCESS:
    return advisors.map(c => 
      +action.advisor.id === +c.id 
        ? { ...action.advisor, nameValue: action.advisor.title }
        : c
    )
  
  case EDIT_ADVISORS_FAILURE:
    return advisors

  case DELETE_ADVISORS_SUCCESS:
    return advisors.filter(c => +c.id !== +action.advisor.id )

  case DELETE_ADVISORS_FAILURE:
    return advisors

  default:
    return advisors
  }
}


const formAdvisors = (advisors, advisor) => {
  const fullAdvisors = advisor ? [...advisors, advisor] : advisors
  return advisors
    ? fullAdvisors.reduce((acc, c) => [...acc, { ...c, nameValue: c.title }]
      , [])
    : null
}