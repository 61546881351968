import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { StyledPanel, StyledScrollBlockSubs, StyledElement } from './Styles'
import TherapyInput from './TherapyInput'


const TherapyEditor = ({ intl, therapies = [], viewOnly, categoryId }) => {

  const [edit, setEdit] = useState(null)
  
  return (

    <StyledPanel className="panel is-primary">

      <p className="panel-heading">
        {intl.formatMessage({ id: 'categories_therapies' })}
      </p>

      <StyledScrollBlockSubs>
        {therapies.length ?
          therapies.map(th => 

            <TherapyInput 
              key={th.id}
              element={th} 
              setEdit={setEdit} 
              edit={+edit === +th.id}
              category={categoryId}
              placeholder={intl.formatMessage({ id: 'categories_therapies_title' })}
              viewOnly={viewOnly}
            />
          )
          : <StyledElement>
            {intl.formatMessage({ id: 'categories_therapies_none' })}
          </StyledElement>
        }
      
        {!viewOnly &&  

          <TherapyInput 
            element={{ id: 'new', title: '' }} 
            setEdit={setEdit} 
            edit={edit === 'new'}
            category={categoryId}
            placeholder={intl.formatMessage({ id: 'categories_therapies_title_new' })}
          />

        }
      
      </StyledScrollBlockSubs>

    </StyledPanel>
  )
}

TherapyEditor.propTypes = {
  therapies: PropTypes.array,
  categoryId: PropTypes.number,
  viewOnly: PropTypes.bool,
}

export default injectIntl(TherapyEditor)
