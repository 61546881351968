import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import TitleElement from './TitleElement'
import { StyledContainer, StyledScrollable, StyledElement } from '../Styles'

import toast from 'helpers/toast'


const PropertiesList = ({ intl, onChange, subAreas }) => {

  const properties = useSelector(s => s.properties) || []
  const { mode } = useSelector(s => s.noApi)
  
  const [open, setOpen] = useState(null)

  const onSelect = (id) => {

    if (mode === 'view') return toast('error', intl.formatMessage({ id: 'select_on_edit_mode_only' }))

    const value = subAreas.find(s => +s === +id) 
      ? subAreas.filter(sa => +sa !== +id)
      : [...subAreas, id]

    onChange({ target: { name: 'subAreas', value } })

  }


  return <StyledContainer className="panel is-primary"> 

    <p className="panel-heading">
      {intl.formatMessage({ id: 'users_assign_area' })}
    </p>

    <StyledScrollable>

      {properties.length 
        ? properties.map(p => 
          <TitleElement 
            className="panel-block"
            key={p.id}
            element={p}
            onChange={onSelect}
            subAreas={subAreas}
            setOpen={setOpen}
            open={open}
          />
        )
        : <StyledElement>
          {intl.formatMessage({ id: 'properties_none' })}
        </StyledElement>
      }

    </StyledScrollable>

  </StyledContainer>
        
}

PropertiesList.propTypes = {
  onChange: PropTypes.func,
  subAreas: PropTypes.array,

}

export default injectIntl(PropertiesList)

