import { LOCALES } from '../locales'

export default {
  [LOCALES.GERMAN]: {

    //?##############################-Users-############################
    'username': 'Benutzername',
    'password': 'Passwort',
    'client_number': 'Kundenummer',


    'password_repeat': 'Passwort wiederholen',
    'mail': 'E-Mail',
    'name': 'Name',
    'surname': 'Nachname',
      

    'input-username': 'Bitte Benutzernamen eingeben',
    'input-password': 'Bitte Passwort eingeben',
    'input-mail': 'Bitte E-Mail eingeben',
    'input-name': 'Bitte Name eingeben',
    'input-surname': 'Bitte Nachname eingeben',
    'users_phone': 'Rufnummer',

    'input-username_taken': 'Dieser Benutzername ist vergeben',
    'input-password_short': 'Das Passwort muss mindestens 6 Zeichen lang sein',
    'input-password_not_equal': 'Die Passwörter stimmen nicht überein',
    'input-mail_invalid': 'Das E-Mail Format ist nicht korrekt',


    'check-input': 'Bitte überprüfen Sie Ihre Eingaben',
    'wrong-user': 'Der Benutzer "{username}" wurde nicht gefunden.',
    'wrong-password': 'Das Passwort ist inkorrekt.',

    'users_assign_area': 'Bereichszuordnung',
    'users_assign_rights': 'Rechtezuordnung',
    'users_rights_button_0': 'Zur Rechtezuweisung',
    'users_rights_button_1': 'Zur Bereichzuweisung',

    'users_areas_none': 'keine Bereiche vorhanden',

    'title': 'Benutzer',


    //?##############################-BOXES-############################
    'boxes_title': 'Paketbezeichnung',
    'boxes_contents': 'Paketinhalt (max 1024 Zeichen)',

    'boxes_title_invalid': 'Bitte geben Sie eine Paketbezeichnung ein',
    'boxes_contents_invalid': 'Bitte geben Sie den Paketinhalt an',

    //?##############################-ADMINS-############################

    'admins_assign_client': 'Einen Kunden zuweisen',
    'admins_jam_admin': 'JAM-Admin',
    'admins_client_admin': 'Kunden-Admin',
    'admins_assigned_client': 'zugewiesener Kunde',

    //?##############################-CLIENTS-###########################
    'name_description': 'Beschreibung',
    'name_description_empty': 'Geben Sie eine Beschreibung ein',
    'logo': 'Logo',
    'logo_empty': 'Bitte wählen Sie ein Logo',
    'primary_color': 'Primäre Farbe',
    'secondary_color': 'Sekundäre Farbe',
    'select_logo': 'Ein Logo wählen',
    'invalid-file': 'Das gewählte Dateiformat ist nicht zugelassen.',
    'remove-file': 'Datei entfernen',
    'invalid_primary': 'ungültige Primäre Farbe',
    'invalid_secondary': 'ungültige Sekundäre Farbe',
    'client_default_password': 'Standartpasswort',
    'client_default_password_enter': 'Geben Sie ein Standartpasswort ein',

    //?##############################-PROPERTIES-##########################
    'properties_title': 'Titel',
    'properties_title_missing': 'Bitte geben Sie einen Titel an',
    'properties_areas_title': 'Titel der Anlage',
    'properties_areas_none': 'Keine Anlagen vorhanden',
    'properties_subareas': 'Unterbereiche',
    'properties_subareas_title': 'Titel des Unterbereichs',
    'properties_subareas_none': 'Keine Unterbereiche vorhanden',
    'properties_subareas_title_new': 'Einen neuen Unterbereich anlegen',
    'properties_areas_title_missing': 'Bitte geben Sie einen Titel für die Anlage an',
    'properties_areas': 'Anlagen',

    'properties_postcode': 'PLZ',
    'properties_postcode_missing': 'Bitte geben Sie eine Postleitzahl an',
    'properties_postcode_short': 'Die Postleitzahl muss mindestens 5 Zeichen lang sein',
    'properties_city': 'Ort',
    'properties_city_missing': 'Bitte geben Sie eine Stadt an',
    'properties_street': 'Straße',
    'properties_street_missing': 'Bitte geben Sie eine Straße an',

    'properties_none': 'keine Liegenschaften hinterlegt',

    //?##############################-CATEGORIES-############################
    'categories_title': 'Bezeichung der Kategorie',
    'categories_title_invalid': 'Bitte geben Sie eine Bezeichnung für die Kategorie ein',
    'categories_therapy_title': 'Bezeichung der Therapie',

    'categories_therapies': 'Therapien',
    'categories_therapies_title': 'Bezeichnung der Therapie',
    'categories_therapies_none': 'Keine Therapien hinterlegt',
    'categories_therapies_title_new': 'Eine neue Therapie anlegen',

    'categories_add_recipient': 'Einen Empfänger zuweisen',
    'categories_recipient_used': 'dieser Empfänger ist der Kategorie: "{category}" zugeordnet und kann nicht gelöscht werden',

    //?##############################-ADVISOR-############################
    'advisors_title': 'Bezeichnung',
    'advisors_title_invalid': 'Bitte geben Sie eine Bezeichnung ein',
    'advisors_content': 'Beschreibung',
    'advisors_file': 'Datei wählen',
    'advisors_select_one': 'Es muss entweder eine Beschreibung eingegeben oder eine Datei ausgewählt werden',
    'advisors_select_only_one': 'Die Kombination aus Beschreibung und PDF-Datei ist nicht zugelassen',


    //?##############################-PACKAGES-############################
    'packages_title': 'Paketbezeichnung', 
    'packages_content': 'Paketinhalt', 

    //?##############################-PACKAGES-############################
    'diseases_title': 'Krankheitsbezeichnung', 
    'diseases_title_invalid': 'Bitte geben SIe eine Krankheitsbezeichnung ein', 
    
    
    //?##############################-CONTACTS-############################
    'contacts_name': 'Name, Nachname',
    'contacts_mail': 'E-Mail',
    'contacts_phone': 'Rufnummer',

    'contacts_name_missing': 'Bitte geben Sie einen Namen/Nachnamen ein',
    'contacts_mail_missing': 'Bitte geben Sie eine E-Mail ein',
    'contacts_phone_missing': 'Bitte geben Sie eine Rufnummer ein',

    'contact_intern': 'Innendienst',
    'contact_extern': 'Außendienst',

    'contact_select_file': 'Ein Profilbild wählen',
   
    
    //?##############################-NAVIGATION-############################
    'title.users': 'Benutzer',
    'title.admins': 'Administratoren',
    'title.boxes': 'Pflegeboxen',
    'title.diseases': 'Vorerkrankungen',
    'title.clients': 'Kunden',
    'title.properties': 'Liegenschaften',
    'title.contacts': 'Kontakte',
    'title.orders': 'Bestellungen',
    'title.patients': 'Patienten',
    'title.advisors': 'Ratgeber',
    'title.categories': 'Kategorien',
    'title.recipients': 'Empfänger',
    
    'create.new.users': 'Neuen Benutzer anlegen',
    'create.new.admins': 'Neuen Administrator anlegen',
    'create.new.boxes': 'Neue Box anlegen',
    'create.new.diseases': 'Neue Grunderkrankung anlegen',
    'create.new.clients': 'Neuen Kunden anlegen',
    'create.new.properties': 'Neue Liegenschaft anlegen',
    'create.new.contacts': 'Neue Kontaktperson anlegen',
    'create.new.advisors': 'Neuen Ratgebereintrag anlegen',
    'create.new.categories': 'Neue Kategorie anlegen',
    'create.new.recipients': 'Neuen Empfänger anlegen',

    'navigation': 'Navigation',
    'hello': 'Hallo {username}  ',
    'logout': 'Logout  ',
    'change_password': 'Passwort ändern  ',


    

    //?##############################-ORDERS-############################

    'orders_header_0': 'Datum',
    'orders_header_1': 'Kategorie',
    'orders_header_2': 'Therapie',
    'orders_header_3': 'Anwender',
    'orders_header_4': 'Patient',

    'orders_missing_docs': 'fehlende Unterlagen',

    //?##############################-MODAL-############################
    'modal_users': 'Benutzer',
    'modal_admins': 'Administrator',
    'modal_boxes': 'Box',
    'modal_diseases': 'Grunderkrankung',
    'modal_clients': 'Kunden',
    'modal_properties': 'Liegenschaft',
    'modal_contacts': 'Kontaktperson',
    'modal_advisors': 'Ratgebereintrag',
    'modal_categories': 'Kategorie',
    'modal_recipients': 'Empfänger',


    'modal_shure_delete': 'Soll {entity} "{value}" wirklich gelöscht werden?',


    //?##############################-ETC-############################
    'accept': 'Bestätigen',
    'edit': 'Bearbeiten',
    'cancel': 'Abbrechen',
    'delete': 'Löschen',
    'select_file': 'Datei auswählen',
    'none': 'keine vorhanden',
    'select_on_edit_mode_only': 'Bitte wechseln Sie in den Bearbeitungsmodus',
    'error_delete': 'Konnte nicht gelöscht werden. Möglicherweise ist das zu löschende Object bereits im Einsatz.',
    'error_create': 'Beim Anlegen ist ein Fehler aufgetreten.',
  }
    
}

