import React , { useEffect }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionHandler from 'components/WorkSpace/SectionHandler'
import { fetchRecipients } from 'r_actions/recipients.actions'
import { fetchCategories } from 'r_actions/categories.actions'

const Recipients = () => {
  const dispatch = useDispatch()

  const recipients = useSelector(s => s.recipients) || []

  useEffect(() => {
    dispatch(fetchRecipients())
    dispatch(fetchCategories())
  }, [dispatch])

  return recipients && <SectionHandler list={recipients} />
}

Recipients.propTypes = {}

export default Recipients
