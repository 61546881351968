export const FETCH_BOXES_SUCCESS = 'FETCH_BOXES_SUCCESS'
export const FETCH_BOXES_FAILURE = 'FETCH_BOXES_FAILURE'

export const CREATE_BOXES_SUCCESS = 'CREATE_BOXES_SUCCESS'
export const CREATE_BOXES_FAILURE = 'CREATE_BOXES_FAILURE'

export const EDIT_BOXES_SUCCESS = 'EDIT_BOXES_SUCCESS'
export const EDIT_BOXES_FAILURE = 'EDIT_BOXES_FAILURE'

export const DELETE_BOXES_SUCCESS = 'DELETE_BOXES_SUCCESS'
export const DELETE_BOXES_FAILURE = 'DELETE_BOXES_FAILURE'

