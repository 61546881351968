import { 
  FETCH_BOXES_SUCCESS, FETCH_BOXES_FAILURE,
  CREATE_BOXES_SUCCESS, CREATE_BOXES_FAILURE,
  EDIT_BOXES_SUCCESS, EDIT_BOXES_FAILURE,
  DELETE_BOXES_SUCCESS, DELETE_BOXES_FAILURE
} from 'r_constants/boxes.constants'
      
import { _fetchBoxes, _createBox, _editBox, _deleteBox } from 'r_services/boxes.service'
      
export const fetchBoxes = () => async dispatch => {
  try {
    const boxes = await _fetchBoxes()
    dispatch({ type: FETCH_BOXES_SUCCESS, boxes })
  } catch (error) {
    dispatch({ type: FETCH_BOXES_FAILURE, error })
  }
}
      
export const createBox = (_box) => async dispatch => {
  try {
    const box = await _createBox(_box)
    dispatch({ type: CREATE_BOXES_SUCCESS, box })
  } catch (error) {
    dispatch({ type: CREATE_BOXES_FAILURE, error })
  }
}
      
export const editBox = (_box) => async dispatch => {
  try {
    const box = await _editBox(_box)
    dispatch({ type: EDIT_BOXES_SUCCESS, box })
  } catch (error) {
    dispatch({ type: EDIT_BOXES_FAILURE, error })
  }
}
      
export const deleteBox = (id) => async dispatch => {
  try {
    const box = await _deleteBox(id)
    dispatch({ type: DELETE_BOXES_SUCCESS, box })
  } catch (error) {
    dispatch({ type: DELETE_BOXES_FAILURE, error })
  }
}
      
      
      