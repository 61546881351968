import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCategory, editCategory, deleteCategory } from 'r_actions/categories.actions'
import { setMode, setSelected } from 'r_actions/noApi.actions'
import CategoryEditorForm from './CategoryEditorForm'

const empty = {  id: null, title: null, recipient: null, therapies: [] }


const CategoryEditor = () => {

  const dispatch = useDispatch()

  const { selected: thisCategory } = useSelector(s => s.noApi)
  const categories = useSelector(s => s.categories || []) 

  const selected = thisCategory && categories.find(p => +p.id === +thisCategory.id)

  const [data, setData] = useState({})
  const [submitted, setSubmitted] = useState(false)
    
  const { id, title } = data

    
  const onChange = e => { 
    const { name, value } = e.target     
    setData(d => ({ ...d, [name]: value })) 
  }
  
  useEffect(() => {
    selected && setData(d => ({ ...d, ...selected }))
    !selected && setData(d => ({ ...d, ...empty }))
  }, [selected])
  
  const cleanStuff = () => {
    setData(empty)
    dispatch(setMode('icon'))
    dispatch(setSelected(null))
    setSubmitted(false)
  }
 
  const onSubmit = () => {
    setSubmitted(true)
    
    if(!!title) {
      id 
        ? dispatch(editCategory({ id, data }))
        : dispatch(createCategory(data))
      cleanStuff()
    }
  }

  const onDelete = () => {
    dispatch(deleteCategory(id))
    cleanStuff()
  }

  return <CategoryEditorForm 
    submitted={submitted}
    data={data}
    onChange={onChange}
    onSubmit={onSubmit}
    onDelete={onDelete}
    setSubmitted={setSubmitted}
  />
    

}

export default CategoryEditor
