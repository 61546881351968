export const FETCH_ADVISORS_SUCCESS = 'FETCH_ADVISORS_SUCCESS'
export const FETCH_ADVISORS_FAILURE = 'FETCH_ADVISORS_FAILURE'

export const CREATE_ADVISORS_SUCCESS = 'CREATE_ADVISORS_SUCCESS'
export const CREATE_ADVISORS_FAILURE = 'CREATE_ADVISORS_FAILURE'

export const EDIT_ADVISORS_SUCCESS = 'EDIT_ADVISORS_SUCCESS'
export const EDIT_ADVISORS_FAILURE = 'EDIT_ADVISORS_FAILURE'

export const DELETE_ADVISORS_SUCCESS = 'DELETE_ADVISORS_SUCCESS'
export const DELETE_ADVISORS_FAILURE = 'DELETE_ADVISORS_FAILURE'

