import initialState from './initialState'
import { CHECK_USERNAME_SUCCESS, CHECK_USERNAME_FAILURE, RESET_USERNAME_CHECK } from 'r_constants/users.constants'


export default function validUsername(validUsername = initialState.validUsername, action) {
  switch (action.type) {
      
  case CHECK_USERNAME_SUCCESS:
    return action.valid

  case CHECK_USERNAME_FAILURE:
    return validUsername

  case RESET_USERNAME_CHECK:
    return true

  default:
    return validUsername
  }
}



