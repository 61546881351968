import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { createClient, editClient, deleteClient } from 'r_actions/clients.actions'
import { setMode, setSelected } from 'r_actions/noApi.actions'
import ClientEditorForm from './ClientEditorForm'
import toast from 'helpers/toast'

const empty = {  id: null, title: null, logo: null, primaryColor: '#fff', secondaryColor: '#fff' }


const ClientEditor = ({ intl }) => {

  const dispatch = useDispatch()

  const { selected } = useSelector(s => s.noApi)

  const [data, setData] = useState({})
  const [submitted, setSubmitted] = useState(false)
    
  const { id, title, password, logo, primaryColor, secondaryColor } = data

    
  const onChange = e => { 
    let { name, value } = e.target    
        
    if(name.includes('Color')) value = '#' + value.split('#').join('')
    setData(d => ({ ...d, [name]: value })) 
  }

  const isHexColor = hex => /^#([0-9A-F]{3}){1,2}$/i.test(hex)
  
  useEffect(() => {
    selected && setData(d => ({ ...d, ...selected }))
    !selected && setData(d => ({ ...d, ...empty }))
  }, [selected])
  
  const cleanStuff = () => {
    setData(empty)
    dispatch(setMode('icon'))
    dispatch(setSelected(null))
    setSubmitted(false)
  }
 
  const onSubmit = () => {
    setSubmitted(true)
    
    if(!!title && !!password && isHexColor(primaryColor) && isHexColor(secondaryColor)) {
      id 
        ? dispatch(editClient({ id, data: { ...data, logo } }))
        : dispatch(createClient(data))
      cleanStuff()
    }

    if(!isHexColor(primaryColor)) toast('error', intl.formatMessage({ id: 'invalid_primary' }))
    if(!isHexColor(secondaryColor)) toast('error', intl.formatMessage({ id: 'invalid_secondary' }))

  }

  const onDelete = () => {
    dispatch(deleteClient(id))
    cleanStuff()
  }

  return <ClientEditorForm 
    submitted={submitted}
    data={data}
    onChange={onChange}
    onSubmit={onSubmit}
    onDelete={onDelete}
    setSubmitted={setSubmitted}
  />
    

}

export default injectIntl(ClientEditor)
