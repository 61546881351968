import initialState from './initialState'
import { 
  FETCH_PATIENTS_SUCCESS, FETCH_PATIENTS_FAILURE,
 
} from 'r_constants/patients.constants'

export default function patients(patients = initialState.patients, action) {
  switch (action.type) {
      
  case FETCH_PATIENTS_SUCCESS:
    return formPatients(action.patients)

  case FETCH_PATIENTS_FAILURE:
    return patients

  default:
    return patients
  }
}


const formPatients = (patients, patient) => {
  const fullPatients = patient ? [...patients, patient] : patients
  return patients
    ? fullPatients.reduce((acc, c) => [...acc, { ...c, nameValue: c.title }]
      , [])
    : null
}