import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Package } from 'react-feather'
import { StyledColumn } from 'Styles'
import EditorButtons from 'components/WorkSpace/editorButtons/EditorButtons'

import Input from 'components/common/input/Input'
import TextArea from 'components/common/textArea/TextArea'

// import Dropdown from 'components/common/Dropdown' //TODO: preselector

const ClientEditorForm = ({ intl, submitted, data, onChange, onSubmit, onDelete, setSubmitted }) => {

  const { title, contents } = data

  const { mode } = useSelector(s => s.noApi)

  const viewOnly = mode === 'view' ? true : false

  return mode === 'icon'  

    ? <Package size={48} color="#949494"/>

    : <div className="columns">

      <StyledColumn className="column">

        <Input
          placeholder={intl.formatMessage({ id: 'boxes_title' })}
          type="title"
          value={title}
          isValid={!submitted || !!title} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'boxes_title_invalid' })}
          viewOnly={viewOnly}
        
        />  

        <TextArea 
          placeholder={intl.formatMessage({ id: 'boxes_contents' })}
          value={contents}
          name="contents"
          isValid={!submitted || !!contents}
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'boxes_contents_invalid' })}
          viewOnly={viewOnly}
          maxLength={1024}
        />
      
    
      </StyledColumn>

      <StyledColumn className="column" />

      <EditorButtons onSubmit={onSubmit} canDelete={true} onDelete={onDelete} reset={() => setSubmitted(false)}/>
    </div>

  
}

ClientEditorForm.propTypes = {
  submitted: PropTypes.bool,
  data: PropTypes.object, 
  onChange: PropTypes.func, 
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  valid: PropTypes.object,
  setSubmitted: PropTypes.func,
}



export default injectIntl(ClientEditorForm)
