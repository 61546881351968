import { 
  FETCH_DISEASES_SUCCESS, FETCH_DISEASES_FAILURE,
  CREATE_DISEASES_SUCCESS, CREATE_DISEASES_FAILURE,
  EDIT_DISEASES_SUCCESS, EDIT_DISEASES_FAILURE,
  DELETE_DISEASES_SUCCESS, DELETE_DISEASES_FAILURE
} from 'r_constants/diseases.constants'
        
import { _fetchDiseases, _createDisease, _editDisease, _deleteDisease } from 'r_services/diseases.service'
        
export const fetchDiseases = () => async dispatch => {
  try {
    const diseases = await _fetchDiseases()
    dispatch({ type: FETCH_DISEASES_SUCCESS, diseases })
  } catch (error) {
    dispatch({ type: FETCH_DISEASES_FAILURE, error })
  }
}
        
export const createDisease = (_disease) => async dispatch => {
  try {
    const disease = await _createDisease(_disease)
    dispatch({ type: CREATE_DISEASES_SUCCESS, disease })
  } catch (error) {
    dispatch({ type: CREATE_DISEASES_FAILURE, error })
  }
}
        
export const editDisease = (_disease) => async dispatch => {
  try {
    const disease = await _editDisease(_disease)
    dispatch({ type: EDIT_DISEASES_SUCCESS, disease })
  } catch (error) {
    dispatch({ type: EDIT_DISEASES_FAILURE, error })
  }
}
        
export const deleteDisease = (id) => async dispatch => {
  try {
    const disease = await _deleteDisease(id)
    dispatch({ type: DELETE_DISEASES_SUCCESS, disease })
  } catch (error) {
    dispatch({ type: DELETE_DISEASES_FAILURE, error })
  }
}
        
        
        