// import { animated } from 'react-spring'
import styled from 'styled-components'

const StyledNavBar = styled.div`
  user-select: none;
  position: absolute;
  top: 0;
  background: white;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin: 0;
`

const StyledHeaderLogo = styled.div`
  cursor: pointer;
  padding: 0;
`

export {
  StyledNavBar,
  StyledHeaderLogo,
}
