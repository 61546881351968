import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Phone } from 'react-feather'
import EditorButtons from 'components/WorkSpace/editorButtons/EditorButtons'
import { StyledColumn } from 'Styles'

import FileUpload from 'components/common/upload/Upload' 
import Input from 'components/common/input/Input'
import Radio from 'components/common/radio/Radio'


const ContactEditorForm = ({ intl, data, onChange, onSubmit, onDelete, valid, setSubmitted, submitted }) => {

  const { name, mail, phone, image, office } = data

  const { mode } = useSelector(s => s.noApi)


  const viewOnly = mode === 'view' ? true : false

  const radioOptions = [
    { title: intl.formatMessage({ id: 'contact_extern' }), value: 0 },
    { title: intl.formatMessage({ id: 'contact_intern' }), value: 1 },
  ]

  return mode === 'icon' 

    ? <Phone size={48} color="#949494"/>

    : <div className="columns">

      <StyledColumn className="column">
         
        <Input
          placeholder={intl.formatMessage({ id: 'contacts_name' })}
          type="name"
          value={name}
          isValid={!submitted || valid.name} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'contacts_name_missing' })}
          viewOnly={viewOnly}
        />   
 
        <Input
          placeholder={intl.formatMessage({ id: 'contacts_mail' })}
          type="mail"
          value={mail}
          isValid={!submitted || valid.mail} 
          onChange={onChange}
          invalidText={!!mail
            ? intl.formatMessage({ id: 'input-mail_invalid' })
            : intl.formatMessage({ id: 'input-mail' })
          }
          viewOnly={viewOnly}
        />   
                
        <Input
          placeholder={intl.formatMessage({ id: 'contacts_phone' })}
          type="phone"
          value={phone}
          pattern="^-?[0-9+]\d*\.?\d*$"
          isValid={!submitted || valid.phone} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'contacts_phone_missing' })}
          viewOnly={viewOnly}
        />  

        <Radio onChange={onChange} options={radioOptions} selected={office} property="office"/> 

      </StyledColumn>

      <StyledColumn className="column">
        <FileUpload
          label={intl.formatMessage({ id: 'contact_select_file' })} 
          file={image}
          onChange={onChange}
          viewOnly={viewOnly}
          property="image"
        />
      </StyledColumn>

      <EditorButtons onSubmit={onSubmit} canDelete={true} onDelete={onDelete} reset={() => setSubmitted(false)}/>
    </div>

  
}

ContactEditorForm.propTypes = {
  data: PropTypes.object, 
  onChange: PropTypes.func, 
  checkName: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  valid: PropTypes.object,
  setSubmitted: PropTypes.func,
}


export default injectIntl(ContactEditorForm)
