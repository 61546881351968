import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { StyledHeader, StyledFooter, StyledSection } from 'components/common/modal/ModalStyle'


const DeleteModal = ({ intl, onDelete, onCancel }) => {

  const location = useLocation()
  const { selected } = useSelector(s => s.noApi)

  const path = location.pathname 
    ? location.pathname.replace('/', '')
    : location.location.pathname.replace('/', '')

  const message = intl.formatMessage({ id: 'modal_shure_delete' }, { 
    entity: intl.formatMessage({ id: `modal_${path}` }), 
    value:  selected.nameValue
  })

  const splitted = message.split(/["]/)

  return <div className="modal is-active">
    <div className="modal-background" />
    <div className="modal-card">

      <StyledHeader className="modal-card-head"/>

      <StyledSection className="modal-card-body">
        <p>{splitted[0]}
          <span style={{ color: '#f14668' }}>
            {splitted[1]}
          </span>{splitted[2]}
        </p>
      </StyledSection>
      
      <StyledFooter className="modal-card-foot">

        <button className="button is-danger" onClick={onDelete}>
          {intl.formatMessage({ id: 'delete' })}
        </button>

        <button className="button" onClick={onCancel}>
          {intl.formatMessage({ id: 'cancel' })}
        </button>

      </StyledFooter>
    </div>
  </div>
}


DeleteModal.propTypes = {
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
}

export default injectIntl(DeleteModal)


