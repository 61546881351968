import styled from 'styled-components'

const StyledPanel = styled.div`
  padding: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledCreateButton = styled.div``

const StyledPanelHeading = styled.div`
  background-color: transparent;
`

const StyledScrollBlock = styled.section`
  overflow-y: auto;
  flex: 1 1 auto;
`

const StyledElement = styled.div`
  line-height: 40px;
  border-bottom: 1px solid #ededed;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${p => p.isSelected ? '#ededed' : 'none'};
 
  &:hover {
    cursor: pointer;
    background-color: #ededed;
  }
`

export {
  StyledPanel,
  StyledScrollBlock,
  StyledCreateButton,
  StyledElement,
  StyledPanelHeading,
}
