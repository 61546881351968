import initialState from './initialState'
import { 
  FETCH_PROPERTIES_SUCCESS, FETCH_PROPERTIES_FAILURE,
  CREATE_PROPERTIES_SUCCESS, CREATE_PROPERTIES_FAILURE,
  EDIT_PROPERTIES_SUCCESS, EDIT_PROPERTIES_FAILURE,
  DELETE_PROPERTIES_SUCCESS, DELETE_PROPERTIES_FAILURE,
  CREATE_AREA_SUCCESS,  CREATE_AREA_FAILURE,
  CREATE_SUB_AREA_SUCCESS, CREATE_SUB_AREA_FAILURE, 
  EDIT_AREA_SUCCESS,  EDIT_AREA_FAILURE,
  EDIT_SUB_AREA_SUCCESS, EDIT_SUB_AREA_FAILURE, 
  DELETE_AREA_SUCCESS,  DELETE_AREA_FAILURE,
  DELETE_SUB_AREA_SUCCESS, DELETE_SUB_AREA_FAILURE, 
} from 'r_constants/properties.constants'

export default function properties(properties = initialState.properties, action) {
  switch (action.type) {
      
  case FETCH_PROPERTIES_SUCCESS:
    return formProperties(action.properties)

    
  case CREATE_PROPERTIES_SUCCESS:
    return formProperties(properties, action.property)

        
  case EDIT_PROPERTIES_SUCCESS:
    return properties.map(c => 
      +action.property.id === +c.id 
        ? { ...action.property, nameValue: action.property.title }
        : c
    )
        
  case DELETE_PROPERTIES_SUCCESS:
    return properties.filter(c => +c.id !== +action.property.id )
       
    
  case CREATE_AREA_SUCCESS:
    return properties.map(p => +p.id === +action.area.property
      ? { ...p, areas: [...p.areas, action.area] }
      : p
    )
            
                       
  case EDIT_AREA_SUCCESS:
    return properties.map(p => +p.id === +action.area.property
      ? { ...p, areas: p.areas.map(pa => +pa.id === action.area.id
        ? action.area
        : pa
      ) }
      : p
    )
                  
  case DELETE_AREA_SUCCESS:
    return properties.map(p => +p.id === +action.area.property
      ? { ...p, areas: p.areas.filter(pa => +pa.id !== +action.area.id) }
      : p
    )


  case CREATE_SUB_AREA_SUCCESS:
    return properties.map(p => +p.id === +action.subArea.property
      ? { ...p, areas: p.areas.map(a => +a.id === +action.subArea.area
        ? { ...a, subAreas: [...a.subAreas, action.subArea] }
        : a
      ) }
      : p
    )

  case EDIT_SUB_AREA_SUCCESS:
    return properties.map(p => +p.id === +action.subArea.property
      ? { ...p, areas: p.areas.map(a => +a.id === +action.subArea.area
        ? { ...a, subAreas: a.subAreas.map(sa => +sa.id === +action.subArea.id
          ? action.subArea
          : sa
        ) }
        : a
      ) }
      : p
    )
      
  case DELETE_SUB_AREA_SUCCESS:
    return properties.map(p => +p.id === +action.subArea.property
      ? { ...p, areas: p.areas.map(a => +a.id === +action.subArea.area
        ? { ...a, subAreas: a.subAreas.filter(sa => +sa.id !== +action.subArea.id) }
        : a
      ) }
      : p
    )    



    
  case DELETE_AREA_FAILURE:
    return properties

  case CREATE_AREA_FAILURE:
    return properties

    
  case FETCH_PROPERTIES_FAILURE:
    return properties
      
      
  case CREATE_PROPERTIES_FAILURE:
    return properties
        
        
  case DELETE_SUB_AREA_FAILURE:
    return properties

  case EDIT_SUB_AREA_FAILURE:
    return properties

  case EDIT_AREA_FAILURE:
    return properties

  case CREATE_SUB_AREA_FAILURE:
    return properties

  case DELETE_PROPERTIES_FAILURE:
    return properties

  case EDIT_PROPERTIES_FAILURE:
    return properties
    
  default:
    return properties
  }
}
  
  
const formProperties = (properties, property) => {
  const fullProperties = property ? [...properties, property] : properties
  return properties
    ? fullProperties.reduce((acc, c) => [...acc, { ...c, nameValue: c.title }]
      , [])
    : null
}