import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useSpring, useChain, config } from 'react-spring'

import { GlassTileStyled } from 'Styles'

const GlassTile = ({ children }) => {

  const auth = useSelector(s => s.auth)
 
  const springRef = useRef()
  const transRef = useRef()

  const { width, minHeight, ...rest } = useSpring({
    ref: springRef,
    config: config.stiff,
    from: {  minHeight: '60vh', width: '20vw' },
    to: { minHeight: auth ? '100vh' : '60vh', width: auth ? '100vw' : '20vw' }
  })

  const opacityleft = auth ? 0.7 : 1
  
  useChain(auth ? [springRef, transRef] : [transRef, springRef], [0, 0])

  return <GlassTileStyled style={{ ...rest, width, minHeight }} opacityleft={opacityleft}>
    {children}
  </GlassTileStyled>

}

GlassTile.propTypes = {
  
}

export default GlassTile