import { RECIPIENTS } from './feathers'

export const _fetchRecipients= async () => {
  const { data } = await RECIPIENTS.find()
  return data
}

export const _createRecipient = async (_recipient) => {
  const recipient = await RECIPIENTS.create(_recipient)
  return recipient
}

export const _editRecipient = async ({ id, data }) => {

  const recipient = await RECIPIENTS.patch(id, data)
  return recipient
}

export const _deleteRecipient = async (id) => {
  const recipient = await RECIPIENTS.remove(id)
  return recipient
}


