import { BOXES } from './feathers'

export const _fetchBoxes = async () => {
  const { data } = await BOXES.find()
  return data
}

export const _createBox = async (_box) => {
  const box = await BOXES.create(_box)
  return box
}

export const _editBox = async ({ id, data }) => {
  const box = await BOXES.patch(id, data)
  return box
}

export const _deleteBox = async (id) => {
  const box = await BOXES.remove(id)
  return box
}


