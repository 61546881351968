import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { checkUsername, resetUsernameCheck, createUser, editUser, deleteUser } from 'r_actions/users.actions'
import { setMode, setSelected } from 'r_actions/noApi.actions'
import { validateUser } from 'helpers/validator'
import AdminEditorForm from './AdminEditorForm'

import { jamPassword } from 'constants/constants'

const CLIENT = 2

const empty = { username: '', mail: '', phone: '', name: '', surname: '', id: null }

const AdminsEditor = () => {
  const dispatch = useDispatch()

  const { role: myRole, client: myClient } = useSelector(s => s.auth)
  const { selected, mode } = useSelector(s => s.noApi)
  const validUsername = useSelector(s => s.validUsername)
  const clients = useSelector(s => s.clients) || []

  const [data, setData] = useState({})
  const [submitted, setSubmitted] = useState(false)
    
  const { id, username, mail, name, surname, role, client } = data
    
  const onChange = e => { 
    const { name, value } = e.target        
    setData(d => ({ ...d, [name]: value })) 
  }

  let password = jamPassword

  if (client && clients.length) password = clients.find(c => +c.id === +client).password

  useEffect(() => {
    selected && setData(d => ({ ...d, ...selected, 
      username: selected.username.substring(selected.username.indexOf('-') + 1) 
    }))
    !selected && setData(d => ({ ...d, ...{ ...empty,  
      role: +myRole === CLIENT ? 2 : 1, 
      client: myClient, 
    } }))
  }, [selected, myRole, myClient])
  
 
  const checkName = (client) => {
    if(!selected || username !== selected.username) dispatch(checkUsername(client ? `${client}-${username}` : username))
  }

  useEffect(() => {
    dispatch(resetUsernameCheck())
  }, [mode, dispatch])    

  const { valid, validUser } = validateUser(data, !!selected, validUsername)

  const cleanStuff = () => {
    setData({ ...empty,  role: +myRole === CLIENT ? 2 : 1, client: myClient })
    dispatch(setMode('icon'))
    dispatch(setSelected(null))
    setSubmitted(false)
  }

  const onSubmit = () => {
    setSubmitted(true)
    
    const editPackage = { username, mail, name, surname, role, client }

    if(validUser) {
      id 
        ? dispatch(editUser({ id, editPackage }))
        : dispatch(createUser({ ...data, password, state: 2 }))

      cleanStuff()
    }
  }

  const onDelete = () => {
    dispatch(deleteUser(id))
    cleanStuff()
  }

  
  return myRole && <AdminEditorForm
    data={data}
    onChange={onChange}
    checkName={checkName}
    onSubmit={onSubmit}
    onDelete={onDelete}
    valid={valid}
    setSubmitted={setSubmitted}
    submitted={submitted}
    clients={clients}
  />
  
}


export default AdminsEditor
