import React , { useEffect }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionHandler from 'components/WorkSpace/SectionHandler'
import { fetchBoxes } from 'r_actions/boxes.actions'

const Boxes = () => {
  const dispatch = useDispatch()

  const boxes = useSelector(s => s.boxes) || []

  useEffect(() => {
    dispatch(fetchBoxes())
  }, [dispatch])

  return boxes && <SectionHandler list={boxes} />
}


export default Boxes
