import React , { useEffect }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOrders } from 'r_actions/orders.actions'
import { OrdersEditor } from 'components/_editors'


const Orders = () => {
  const dispatch = useDispatch()

  const orders = useSelector(s => s.orders) || []

  useEffect(() => {
    dispatch(fetchOrders())
  }, [dispatch])

  return <OrdersEditor orders={orders}/>
}


export default Orders

