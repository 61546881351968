import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import LoginForm from './LoginForm'
import { login } from '../../r_actions/auth.actions'
import toast from 'helpers/toast'


const Login = ({ intl }) => {
  const dispatch = useDispatch()
    
  const [{ username, password, clientNumber, submitted }, setData] = useState({ username: '', password: '', clientNumber: '', submitted: false })
 
  const onChange = e => { 
    const { name, value } = e.target        
    setData(d => ({ ...d, [name]: value })) 
  }

  const onSubmit = () => {
    const wrongUser = intl.formatMessage({ id: 'wrong-user' }, { username })
    const wrongPass = intl.formatMessage({ id: 'wrong-password' })
    
    if(username === '' || password === '') {
      setData(d => ({ ...d, submitted: true }))
      return toast('error', intl.formatMessage({ id: 'check-input' }))
    }
    dispatch(login({ username, password, clientNumber }, wrongPass, wrongUser))
  }
  
   
  return (    
    <LoginForm 
      username={username} 
      password={password} 
      clientNumber={clientNumber}
      onChange={onChange} 
      submitted={submitted}
      onSubmit={onSubmit}
    />
  )
   
}


  
export default injectIntl(Login)