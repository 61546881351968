import { 
  FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAILURE,
} from 'r_constants/orders.constants'
          
import { _fetchOrders } from 'r_services/orders.service'
          
export const fetchOrders = () => async dispatch => {
  try {
    const orders = await _fetchOrders()
    dispatch({ type: FETCH_ORDERS_SUCCESS, orders })
  } catch (error) {
    dispatch({ type: FETCH_ORDERS_FAILURE, error })
  }
}
          
   
          
          