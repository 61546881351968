import React from 'react'
import PropTypes from 'prop-types'
import Selectable from './Selectable'


const SubAreaSelector = ({ subAreas, onChange, selectable }) => {

  return <div>

    {
      selectable.map(s => 
        <Selectable 
          key={s.id}
          subArea={s}
          selected={!!subAreas.find(sa => +sa === +s.id)}
          onChange={onChange}
        
        />
      )
    }

  </div>
        
}

SubAreaSelector.propTypes = {
  subAreas: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  selectable: PropTypes.array, 
}

export default SubAreaSelector

