import feathers from '@feathersjs/client'
import io from 'socket.io-client'
import feathersAPI from 'helpers/api_config'

const socket = io(feathersAPI)

const app = feathers()
  .configure(feathers.socketio(socket,{ timeout: 10000 }))
  .configure(feathers.authentication({ storage: localStorage }))

// feathers services
export const USERS = app.service('users')
export const CLIENTS = app.service('clients')
export const CHECK_USERNAME = app.service('check-username')
export const CONTACTS = app.service('contacts')
export const PROPERTIES = app.service('properties')
export const AREAS = app.service('areas')
export const SUB_AREAS = app.service('sub-areas')
export const BOXES = app.service('care-boxes')
export const DISEASES = app.service('diseases')
export const ORDERS = app.service('orders')
export const PATIENTS = app.service('patients')
export const CATEGORIES = app.service('categories')
export const ADVISORS = app.service('advisors')
export const THERAPIES = app.service('therapies')
export const RECIPIENTS = app.service('recipients')


export default app
