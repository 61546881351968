import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { FileText } from 'react-feather'
import { StyledColumn } from 'Styles'
import EditorButtons from 'components/WorkSpace/editorButtons/EditorButtons'

import Input from 'components/common/input/Input'
import FileUpload from 'components/common/upload/Upload' 
import TextArea from 'components/common/textArea/TextArea'

// import Dropdown from 'components/common/Dropdown' //TODO: preselector

const AdvisorEditorForm = ({ intl, submitted, data, onChange, onSubmit, onDelete, setSubmitted }) => {

  const { title, content, file } = data

  const { mode } = useSelector(s => s.noApi)

  const viewOnly = mode === 'view' ? true : false

  return mode === 'icon'  

    ? <FileText size={48} color="#949494"/>

    : <div className="columns">

      <StyledColumn className="column">

        <Input
          placeholder={intl.formatMessage({ id: 'advisors_title' })}
          type="title"
          value={title}
          isValid={!!title || !submitted} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'advisors_title_invalid' })}
          viewOnly={viewOnly}
        
        />  


        <TextArea 
          placeholder={intl.formatMessage({ id: 'advisors_content' })}
          value={content}
          name="content"
          isValid={!submitted || (!!content || !!file)}
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'advisors_select_one' })}
          viewOnly={viewOnly}
        />

    
      </StyledColumn>

      <StyledColumn className="column">

        <FileUpload
          label={intl.formatMessage({ id: 'advisors_file' })} 
          file={file}
          onChange={onChange}
          viewOnly={viewOnly}
          property="file"
          additionalMime="application/pdf"
        />

      </StyledColumn>

      <EditorButtons onSubmit={onSubmit} canDelete={true} onDelete={onDelete} reset={() => setSubmitted(false)}/>
    </div>

  
}

AdvisorEditorForm.propTypes = {
  submitted: PropTypes.bool,
  data: PropTypes.object, 
  onChange: PropTypes.func, 
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  valid: PropTypes.object,
  setSubmitted: PropTypes.func,
}



export default injectIntl(AdvisorEditorForm)
