import styled from 'styled-components'

const StyledPanel = styled.article`
  padding: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`



const StyledListInput = styled.input`
  margin-right: 10px;
  cursor: pointer;
`

const StyledScrollBlock = styled.section`
  max-height: 15rem;
  overflow-y: auto;
  flex: 1 1 auto;
`
const StyledScrollBlockSubs = styled.section`
max-height: 35rem;
  overflow-y: auto;
  flex: 1 1 auto;
`

const StyledElement = styled.div`
  line-height: 25px;
  border-bottom: 1px solid #ededed;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:active {
    cursor: pointer;
    background-color: #ededed;
  }
`

const StyledEditableElement = styled.div`
  border-bottom: 1px solid #ededed;
`

const SytledTitelRow = styled.div`
padding-right: 1rem;
button{
  margin-top: 0.8rem;
}


`

const StyledTitle = styled.div`
`


const StyledButton = styled.button`
  height: 100%;
  margin-left: 5px;
`


export {
  StyledPanel,
  StyledScrollBlock,
  StyledElement,
  StyledListInput,
  StyledButton,
  StyledEditableElement,
  SytledTitelRow,
  StyledScrollBlockSubs,
  StyledTitle,
}
