import styled from 'styled-components'

const StyledHeader = styled.div`
  background-color: white;
  border: none;
  padding: 5px;
  font-weight: bold;
`


const StyledSection = styled.div`
text-align: center;
font-weight: bold;
`


const StyledFooter = styled.div`
/* background-color: #c9c5c5ad; */
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  background: white;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3)
  );
`
export { StyledHeader, StyledFooter, StyledSection }
