export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const FOREIGN_LOGIN_SUCCESS = 'FOREIGN_LOGIN_SUCCESS'
export const FOREIGN_LOGIN_ERROR = 'FOREIGN_LOGIN_ERROR'


  

