export const validateUser = (user, editMode, validUsername) => {
  const { username, mail, name, surname, role } =  user
  const v = {
    username: !!username && validUsername,
    mail: validateEmail(mail),
    name: !!name,
    surname: !!surname,
    role: !!role
  }
  const validUser = v.username && v.mail && v.name && v.surname && v.role
  return { valid: v, validUser }
}


export const validateContact = (contact) => {
  const { name, phone, mail } = contact
  const v = {
    name: !!name ,
    phone: !!phone && phone.toString().length >=6,
    mail: validateEmail(mail) 
  }
  const validContact = v.name && v.phone && v.mail
  return { valid: v, validContact }
}

    
export const validateEmail = (mail) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(mail).toLowerCase())
}



