import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { User, FileText, Package, Grid, Smile, Phone, Thermometer, Home, AtSign, Users, Archive, Menu, LogOut, Lock } from 'react-feather'
import { StyledUserButton, StyledDropDownWrapper, StyledUserMenu, StyledMenuElement, StyledSub, StyledSubNav } from './Styles'
import PasswordModal from './PasswordModal'


const icon = { 
  admins: <User size={14} />, 
  users: <Users size={14} />, 
  properties: <Home size={14} />, 
  boxes: <Package size={14} />, 
  diseases: <Thermometer size={14} />, 
  clients: <Smile size={14} />, 
  contacts: <Phone size={14} />, 
  orders: <Archive size={14} />, 
  advisors: <FileText size={14} />, 
  categories: <Grid size={14} />, 
  recipients: <AtSign size={14} />, 

}


const NavigationDropdown = ({ intl, routes, path, onSelect, logout }) => {

  const { state } = useSelector(s => s.auth)

  const [ activeMenu, setActiveMenu ] = useState('')

  const [ modal, setModal ] = useState(false)

  useEffect(() => +state === 2 && setModal('is-active'), [state])

  const elementColor = activeMenu ? '#29b3a7': 'black'

  const onNavigate = r => {
    onSelect(r)
    setActiveMenu('')
  }

  return (
    <>
    
      { modal && <PasswordModal setModal={setModal}/> }

      <StyledDropDownWrapper className={`dropdown column p-0 ${activeMenu}`}
        onMouseEnter={() => setActiveMenu('is-active')} 
        onMouseLeave={() => setActiveMenu('')}
      >

        <div className="dropdown-trigger">

          <StyledUserButton  > 
            <StyledSubNav><Menu size={20} color={elementColor}/></StyledSubNav>
            <StyledSubNav style={{ color: elementColor }}>{intl.formatMessage({ id: `title.${path}` })}</StyledSubNav>
          </StyledUserButton> 

        </div>

        <StyledUserMenu className="dropdown-menu" id="dropdown-menu" role="menu">

          <div className="dropdown-content">
          
            {routes.map(r => 
              <StyledMenuElement key={r} className="dropdown-item" onClick={() => onNavigate(r)}>

                <StyledSub>{icon[r]}</StyledSub>
                <StyledSub>{intl.formatMessage({ id: `title.${r}` }) }</StyledSub>
                             
              </StyledMenuElement>
            )}

            <StyledMenuElement className="dropdown-item" onClick={() => setModal(true)}>

              <StyledSub> <Lock size={14}/></StyledSub>
              <StyledSub>{intl.formatMessage({ id: 'change_password' })}</StyledSub>
     
            </StyledMenuElement>

            <StyledMenuElement className="dropdown-item" onClick={logout}>

              <StyledSub> <LogOut size={14}/></StyledSub>
              <StyledSub>{intl.formatMessage({ id: 'logout' })}</StyledSub>
              
            </StyledMenuElement>
         
          </div>

        </StyledUserMenu>

      </StyledDropDownWrapper>
    </>
    
  )
}



NavigationDropdown.propTypes = {
  routes: PropTypes.array, 
  path: PropTypes.string,
  onSelect: PropTypes.func,
  logout: PropTypes.func,
}



export default injectIntl(NavigationDropdown)


