import React from 'react'
import { StyledNavBar, StyledHeaderLogo } from './Styles'
import PropTypes from 'prop-types'
import NavigationDropdown from './dropdowns/NavigationDropdown'
import logo from 'iconsImages/logo.png'


const NavigationBar = ({ logout, routes, path, onSelect, isSmallScreen }) => {
 
  return (
    
    <StyledNavBar className="columns">

      { path && <NavigationDropdown routes={routes} path={path} onSelect={onSelect} logout={logout}/>}

      { !isSmallScreen && <>
        <div className="column is-9"/>

        <StyledHeaderLogo className="image column is-1" onClick={() => onSelect(path)}>
          <img src={logo} alt="" />
        </StyledHeaderLogo>
      </>
      }
      
    </StyledNavBar>
  )
}

NavigationBar.propTypes = {
  logout: PropTypes.func,
  routes: PropTypes.array,
  path: PropTypes.string,
  onSelect: PropTypes.func,
  isSmallScreen: PropTypes.bool,
}



export default NavigationBar
