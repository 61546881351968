import initialState from './initialState'
import { 
  FETCH_RECIPIENTS_SUCCESS, FETCH_RECIPIENTS_FAILURE,
  CREATE_RECIPIENTS_SUCCESS, CREATE_RECIPIENTS_FAILURE,
  EDIT_RECIPIENTS_SUCCESS, EDIT_RECIPIENTS_FAILURE,
  DELETE_RECIPIENTS_SUCCESS, DELETE_RECIPIENTS_FAILURE
} from 'r_constants/recipients.constants'

export default function recipients(recipients = initialState.recipients, action) {
  switch (action.type) {
      
  case FETCH_RECIPIENTS_SUCCESS:
    return formRecipients(action.recipients)


  case CREATE_RECIPIENTS_SUCCESS:
    return formRecipients(recipients, action.recipient)


  case EDIT_RECIPIENTS_SUCCESS:
    return recipients.map(r => 
      +action.recipient.id === +r.id 
        ? { ...action.recipient, nameValue: action.recipient.mail }
        : r
    )
  
  case DELETE_RECIPIENTS_SUCCESS:
    return recipients.filter(r => +r.id !== +action.recipient.id )


  case FETCH_RECIPIENTS_FAILURE:
    return recipients

  case CREATE_RECIPIENTS_FAILURE:
    return recipients

  case EDIT_RECIPIENTS_FAILURE:
    return recipients

  case DELETE_RECIPIENTS_FAILURE:
    return recipients

  default:
    return recipients
  }
}


const formRecipients = (recipients, recipient) => {
  const fullRecipients = recipient ? [...recipients, recipient] : recipients
  return recipients
    ? fullRecipients.reduce((acc, r) => [...acc, { ...r, nameValue: r.mail }]
      , [])
    : null
}