import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'
import { Scrollbars } from 'react-custom-scrollbars'
import { File, Viewer } from './Styles'
import PDFNavigation from './PDFNavigation'

const PDFViewer = ({ path }) => {

  const [{ numPages, pageNumber }, setData] = useState({ numPages: null, pageNumber: 1 })

  const onDocumentLoadSuccess = ({ numPages }) => setData(d => ({ ...d, numPages }))


  const onNavigate = add => add
    ? +pageNumber < +numPages && setData(d => ({ ...d, pageNumber: pageNumber + 1 }))
    : +pageNumber > 1 && setData(d => ({ ...d, pageNumber: pageNumber - 1 }))
  
  
  return <File>
    { 
      numPages > 1 && <PDFNavigation 
        pages={numPages} 
        activePage={pageNumber}
        onNavigate={onNavigate}
      />
    }

    <Viewer>
      <Scrollbars style={{ height: '60vh' }}>
        <Document file={path} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </Scrollbars>
    </Viewer>
  </File>
  
}

export default PDFViewer

