import React , { useEffect }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionHandler from 'components/WorkSpace/SectionHandler'
import { fetchCategories } from 'r_actions/categories.actions'
import { fetchRecipients } from 'r_actions/recipients.actions'

const Categories = () => {
  const dispatch = useDispatch()

  const categories = useSelector(s => s.categories) || []

  useEffect(() => {
    dispatch(fetchCategories())
    dispatch(fetchRecipients())
  }, [dispatch])

  return categories && <SectionHandler list={categories} />
}


export default Categories
