import { logout } from '../r_actions/auth.actions'

const checkTokenExpirationMiddleware = store => next => action => {
  
  const accessToken = localStorage.getItem('feathers-jwt') 
  const token = (accessToken && JSON.parse(atob(accessToken.split('.')[1]))) || {}
  
  if (token.exp < Date.now() / 1000) {
    next(action)
    localStorage.clear()
    store.dispatch(logout(true))
  }
  next(action)
}

export default checkTokenExpirationMiddleware