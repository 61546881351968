import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Square, CheckSquare } from 'react-feather'
import { StyledContainer, StyledScrollable, StyledElement } from './Styles'
import toast from 'helpers/toast'


const RightsSelector = ({ intl, selected, onChange }) => {

  const { mode } = useSelector(s => s.noApi)
  const categories = useSelector(s => s.categories) || []


  const onSelect = (id) => {
    if (mode === 'view') return toast('error', intl.formatMessage({ id: 'select_on_edit_mode_only' }))

    const newSelected = selected.includes(id)
      ? selected.filter(s => +s !== +id)
      : [...selected, id]
  
    onChange({ target: { name: 'restrict' , value: newSelected } })
  }
 
  return <StyledContainer className="panel is-primary"> 

    <p className="panel-heading">
      {intl.formatMessage({ id: 'users_assign_rights' })}
    </p>

    <StyledScrollable>

      {
        categories.map(c => 
          <StyledElement className="columns" key={c.id} onClick={() => onSelect(c.id)}>

            <div className="column is-10">
              {c.title}
            </div>


            <div className="column">
              {
                !selected.includes(+c.id) 
                  ? <CheckSquare color="#6cba2c"/>
                  : <Square color="#050505a6"/>
              }

            </div>

          </StyledElement>
        )
      }

    </StyledScrollable>

  </StyledContainer>
        
}

RightsSelector.propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func,
}

export default injectIntl(RightsSelector)

