export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const CREATE_USERS_SUCCESS = 'CREATE_USERS_SUCCESS'
export const CREATE_USERS_FAILURE = 'CREATE_USERS_FAILURE'

export const EDIT_USERS_SUCCESS = 'EDIT_USERS_SUCCESS'
export const EDIT_USERS_FAILURE = 'EDIT_USERS_FAILURE'

export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE'

export const CHECK_USERNAME_SUCCESS = 'CHECK_USERNAME_SUCCESS'
export const CHECK_USERNAME_FAILURE = 'CHECK_USERNAME_FAILURE'

export const RESET_USERNAME_CHECK = 'RESET_USERNAME_CHECK'


  

