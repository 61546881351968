import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { setMode, setSelected } from 'r_actions/noApi.actions'

const Submit = ({ intl, onSubmit, reset }) => {
  const dispatch = useDispatch()

  const { selectedBackup } = useSelector(s => s.noApi)
  
  const onCancel = () => {
    dispatch(setMode('view'))
    dispatch(setSelected(selectedBackup))
    reset()
  }

  return <>
    <button
      className="button is-fullwidth is-danger column"
      onClick={onCancel}
    >
      {intl.formatMessage({ id: 'cancel' })}
    </button>

    <div className="is-one-fifth column" />

    <button
      className="button is-fullwidth is-success column"
      onClick={onSubmit}
    >
      {intl.formatMessage({ id: 'accept' })}
    </button>
  </>
}


  


Submit.propTypes = {
  onSubmit: PropTypes.func,
  reset: PropTypes.func,
}

export default injectIntl(Submit)
