import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Input from 'components/common/input/Input'
import logo from 'iconsImages/logo.png'
import { LoginFormStyled, Image, Button } from './Styles'

const LoginForm = ({ username, password, clientNumber, onChange, onSubmit, submitted, intl }) => {

  const handleKeyPress = (target) => {
    if(+target.charCode === 13) onSubmit()  
  }

  return (

    <LoginFormStyled onKeyPress={handleKeyPress}>   

      <Image className="image">
        <img src={logo} alt="" />
      </Image>

      <h1 className="title">Admin Portal</h1>

     
      <Input
        placeholder={intl.formatMessage({ id: 'username' })}
        type="username"
        value={username}
        isValid={!!username || !submitted} 
        onChange={onChange}
        invalidText={intl.formatMessage({ id: 'input-username' })}
      />     

      <Input
        placeholder={intl.formatMessage({ id: 'password' })}
        type="password"
        value={password}
        isValid={!!password || !submitted} 
        onChange={onChange}
        invalidText={intl.formatMessage({ id: 'input-password' })}
      />     

      <Input
        placeholder={intl.formatMessage({ id: 'client_number' })}
        type="clientNumber"
        value={clientNumber}
        onChange={onChange}
      />     
       
                
      <Button className="button is-fullwidth" onClick={onSubmit}>Login</Button>
    </LoginFormStyled>
  )
}

LoginForm.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  clientNumber: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  submitted: PropTypes.bool,
}

export default injectIntl(LoginForm)