import sha512 from 'js-sha512'
import { USERS, CHECK_USERNAME } from './feathers'

export const _fetchUsers = async () => {
  const { data } = await USERS.find({ query: { state: 1 } })
  return data
}

export const _createUser = async (_user) => {
  _user = { ..._user, password: sha512(_user.password) }
  const user = await USERS.create(_user)
  return user
}

export const _editUser = async ({ id, editPackage }) => {
  if (editPackage.password) editPackage = { ...editPackage, password: sha512(editPackage.password) }
  const user = await USERS.patch(id, editPackage)
  return user
}

export const _deleteUser = async (id) => {
  const user = await USERS.remove(id)
  return user
}

export const _checkUsername = async (username) => {
  const { free } = await CHECK_USERNAME.get(username)
  return free
}


