import React , { useEffect }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionHandler from 'components/WorkSpace/SectionHandler'
import { fetchDiseases } from 'r_actions/diseases.actions'

const Diseases = () => {
  const dispatch = useDispatch()

  const diseases = useSelector(s => s.diseases) || []

  useEffect(() => {
    dispatch(fetchDiseases())
  }, [dispatch])

  return diseases && <SectionHandler list={diseases} />
}


export default Diseases
