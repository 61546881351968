import { 
  FETCH_CLIENTS_SUCCESS, FETCH_CLIENTS_FAILURE,
  CREATE_CLIENTS_SUCCESS, CREATE_CLIENTS_FAILURE,
  EDIT_CLIENTS_SUCCESS, EDIT_CLIENTS_FAILURE,
  DELETE_CLIENTS_SUCCESS, DELETE_CLIENTS_FAILURE
} from 'r_constants/clients.constants'
    
import { _fetchClients, _createClient, _editClient, _deleteClient } from 'r_services/clients.service'
    
export const fetchClients = () => async dispatch => {
  try {
    const clients = await _fetchClients()
    dispatch({ type: FETCH_CLIENTS_SUCCESS, clients })
  } catch (error) {
    dispatch({ type: FETCH_CLIENTS_FAILURE, error })
  }
}
    
export const createClient = (_client) => async dispatch => {
  try {
    const client = await _createClient(_client)
    dispatch({ type: CREATE_CLIENTS_SUCCESS, client })
  } catch (error) {
    dispatch({ type: CREATE_CLIENTS_FAILURE, error })
  }
}
    
export const editClient = (_client) => async dispatch => {
  try {
    const client = await _editClient(_client)
    dispatch({ type: EDIT_CLIENTS_SUCCESS, client })
  } catch (error) {
    dispatch({ type: EDIT_CLIENTS_FAILURE, error })
  }
}
    
export const deleteClient = (id) => async dispatch => {
  try {
    const client = await _deleteClient(id)
    dispatch({ type: DELETE_CLIENTS_SUCCESS, client })
  } catch (error) {
    dispatch({ type: DELETE_CLIENTS_FAILURE, error })
  }
}
    
    
    