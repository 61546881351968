export const FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS'
export const FETCH_PROPERTIES_FAILURE = 'FETCH_PROPERTIES_FAILURE'

export const CREATE_PROPERTIES_SUCCESS = 'CREATE_PROPERTIES_SUCCESS'
export const CREATE_PROPERTIES_FAILURE = 'CREATE_PROPERTIES_FAILURE'

export const EDIT_PROPERTIES_SUCCESS = 'EDIT_PROPERTIES_SUCCESS'
export const EDIT_PROPERTIES_FAILURE = 'EDIT_PROPERTIES_FAILURE'

export const CREATE_AREA_SUCCESS = 'CREATE_AREA_SUCCESS'
export const CREATE_AREA_FAILURE = 'CREATE_AREA_FAILURE'

export const CREATE_SUB_AREA_SUCCESS = 'CREATE_SUB_AREA_SUCCESS'
export const CREATE_SUB_AREA_FAILURE = 'CREATE_SUB_AREA_FAILURE'

export const EDIT_AREA_SUCCESS = 'EDIT_AREA_SUCCESS'
export const EDIT_AREA_FAILURE = 'EDIT_AREA_FAILURE'

export const EDIT_SUB_AREA_SUCCESS = 'EDIT_SUB_AREA_SUCCESS'
export const EDIT_SUB_AREA_FAILURE = 'EDIT_SUB_AREA_FAILURE'

export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS'
export const DELETE_AREA_FAILURE = 'DELETE_AREA_FAILURE'

export const DELETE_SUB_AREA_SUCCESS = 'DELETE_SUB_AREA_SUCCESS'
export const DELETE_SUB_AREA_FAILURE = 'DELETE_SUB_AREA_FAILURE'


export const DELETE_PROPERTIES_SUCCESS = 'DELETE_PROPERTIES_SUCCESS'
export const DELETE_PROPERTIES_FAILURE = 'DELETE_PROPERTIES_FAILURE'


  

