export default {
  auth: null,
  noApi: { selected: null, selecteBackup: null, mode: 'icon' },
  validUsername: true,
  admins: null,
  users: null,
  clients: null,
  contacts: null,
  properties: null,
  boxes: null,
  diseases: null,
  orders: null,
  patients: null,
  advisors: null,
  categories: null,
  recipients: null,
  error: null,
 
}
    