import React from 'react'
import PropTypes from 'prop-types'
import SubAreaSelector from '../subAreas/SubAreaSelector'
import { ChevronDown, ChevronUp } from 'react-feather'



const AreaElement = ({ element, open, setOpen, onChange, subAreas }) => {
  const { id, title, subAreas: selectable } = element

  const length = !!selectable.length

  const onOpen = () => length && setOpen(+open === +id ? null : id ) 

  const hasSelected = !!subAreas.find(sa => selectable.find(se => +se.id === +sa))

  const color = hasSelected ? '#6cba2c' : '#050505a6'
  
  return <div>

    <div className="columns" onClick={onOpen}>

      <div className="column is-10">
        {title}
      </div>

      {
        length &&
           <div className="column">
             { +open === +id
               ? <ChevronUp color={color}/>
               : <ChevronDown color={color}/>
             }
           </div>
      }
      
    </div>

    { +open === +id && 
        <SubAreaSelector
          subAreas={subAreas}
          onChange={onChange}
          selectable={selectable}
        />

    }

  </div>
        
}

AreaElement.propTypes = {
  element: PropTypes.object,
  onChnage: PropTypes.func,
  subAreas: PropTypes.array,
  setOpen: PropTypes.func,
  open: PropTypes.number,
}

export default AreaElement

