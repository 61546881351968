export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'

export const CREATE_CATEGORIES_SUCCESS = 'CREATE_CATEGORIES_SUCCESS'
export const CREATE_CATEGORIES_FAILURE = 'CREATE_CATEGORIES_FAILURE'

export const EDIT_CATEGORIES_SUCCESS = 'EDIT_CATEGORIES_SUCCESS'
export const EDIT_CATEGORIES_FAILURE = 'EDIT_CATEGORIES_FAILURE'

export const DELETE_CATEGORIES_SUCCESS = 'DELETE_CATEGORIES_SUCCESS'
export const DELETE_CATEGORIES_FAILURE = 'DELETE_CATEGORIES_FAILURE'


export const CREATE_THERAPIES_SUCCESS = 'CREATE_THERAPIES_SUCCESS'
export const CREATE_THERAPIES_FAILURE = 'CREATE_THERAPIES_FAILURE'

export const EDIT_THERAPIES_SUCCESS = 'EDIT_THERAPIES_SUCCESS'
export const EDIT_THERAPIES_FAILURE = 'EDIT_THERAPIES_FAILURE'

export const DELETE_THERAPIES_SUCCESS = 'DELETE_THERAPIES_SUCCESS'
export const DELETE_THERAPIES_FAILURE = 'DELETE_THERAPIES_FAILURE'

