import initialState from './initialState'
import { SET_MODE, SET_SELECTED, SET_SELECTED_BACKUP } from 'r_constants/noApi.constants'
import { CREATE_PROPERTIES_SUCCESS } from 'r_constants/properties.constants'


export default function noApi(noApi = initialState.noApi, action) {
  switch (action.type) {
      
  case SET_MODE:
    return { ...noApi, mode: action.mode }

  case SET_SELECTED:
    return { ...noApi, selected: action.selected }

  case SET_SELECTED_BACKUP:
    return { ...noApi, selectedBackup: action.selected }

  case CREATE_PROPERTIES_SUCCESS:
    return { ...noApi, selected: action.property }
  
  default:
    return noApi
  }
}