import React from 'react'
import PropTypes from 'prop-types'
import { StyledSelector, StyledWrapper } from './Styles'
import { HexColorPicker } from 'react-colorful'
import Input from 'components/common/input/Input'


const ColorSelector = ({ placeholder, type, value, onChange, viewOnly=false }) => {

  const setColor = (value) => onChange({ target: { name: type, value } })
 
  return (    
    <StyledWrapper >

      <Input
        placeholder={placeholder}
        type={type}
        value={value ? value : '#ffff'}
        onChange={onChange}
        viewOnly={viewOnly}
        // addotionalStyle={{ backgroundColor: value }}
      />  
        
      {!viewOnly &&      
        <StyledSelector>
          <HexColorPicker 
            color={value ? value : '#ffff'} 
            onChange={setColor} 
          />
           
        </StyledSelector>
      }
      
    </StyledWrapper>
  )
   
}


ColorSelector.propTypes = {
  placeholder: PropTypes.string, 
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func, 
  viewOnly: PropTypes.bool,
}

  
export default ColorSelector