import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route   } from 'react-router-dom'
import 'bulma'

import LoginPage from 'components/auth/Login'
import GlassTile from 'components/common/GlassTile'
import Redirector from 'components/redirector/Redirector'
import Navigation from 'components/WorkSpace/navigation/Navigation'
import CurrentLocale from 'intl/CurrentLocale'

import { AppStyled } from './Styles'

import { authenticate } from 'r_actions/auth.actions'


const App =  () => {

  const dispatch = useDispatch()

  const auth = useSelector(s => s.auth)

  useEffect(() => {
    if(!auth) {
      dispatch(authenticate())
    }
  }, [auth, dispatch])

  return (
    <AppStyled>
      <GlassTile>
        <Router>

          { auth && <Navigation /> }
    
          { auth 
            ? <Redirector/>
            : <Switch> <Route path="/" component={LoginPage} /> </Switch>
          }

          <CurrentLocale/>

        </Router>

      </GlassTile>
    </AppStyled>
    
  )
}

export default App


//TODO: https://github.com/feathericons/feather#feather
//TODO: https://github.com/malte-wessel/react-custom-scrollbars
