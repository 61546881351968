import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { StyledPanel, StyledScrollBlockSubs, StyledElement } from '../Styles'
import ListInput from './ListInput'


const SubAreas = ({ intl, subAreas, areaId, viewOnly }) => {

  const [edit, setEdit] = useState(null)
  
  return (

    <StyledPanel className="panel is-primary">

      <p className="panel-heading">
        {intl.formatMessage({ id: 'properties_subareas' })}
      </p>

      <StyledScrollBlockSubs>
        {subAreas.length ?
          subAreas.map(sa => 

            <ListInput 
              key={sa.id}
              element={sa} 
              setEdit={setEdit} 
              edit={+edit === +sa.id}
              area={areaId}
              placeholder={intl.formatMessage({ id: 'properties_subareas_title' })}
              viewOnly={viewOnly}
            />
          )
          : <StyledElement>
            {intl.formatMessage({ id: 'properties_subareas_none' })}
          </StyledElement>
        }
      
        {!viewOnly &&  

          <ListInput 
            element={{ id: 'new', title: '' }} 
            setEdit={setEdit} 
            edit={edit === 'new'}
            area={areaId}
            placeholder={intl.formatMessage({ id: 'properties_subareas_title_new' })}
          />

        }
      
      </StyledScrollBlockSubs>

    </StyledPanel>
  )
}

SubAreas.propTypes = {
  subAreast: PropTypes.array,
  areaId: PropTypes.number,
  viewOnly: PropTypes.bool,
}

export default injectIntl(SubAreas)
