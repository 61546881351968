import initialState from './initialState'
import { 
  FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE,
  CREATE_USERS_SUCCESS, CREATE_USERS_FAILURE,
  EDIT_USERS_SUCCESS, EDIT_USERS_FAILURE,
  DELETE_USERS_SUCCESS, DELETE_USERS_FAILURE
} from 'r_constants/users.constants'

const USER = 3

export default function users(admins = initialState.admins, action) {
  switch (action.type) {
      
  case FETCH_USERS_SUCCESS:
    return formAdmins(action.users)
    
  case FETCH_USERS_FAILURE:
    return admins

  case CREATE_USERS_SUCCESS:
    return formAdmins(admins, action.user)

  case CREATE_USERS_FAILURE:
    return admins

  case EDIT_USERS_SUCCESS:
    return admins.map(u => 
      +action.user.id === +u.id
        ? { ...action.user, 
          nameValue: action.user.name && action.user.surname 
            ? `${action.user.surname} ${action.user.name}` 
            : action.user.username 
        }
        : u
    )

  case EDIT_USERS_FAILURE:
    return admins

  case DELETE_USERS_SUCCESS:
    return admins.filter(a => +a.id !== action.user.id )

  case DELETE_USERS_FAILURE:
    return admins

  default:
    return admins
  }
}

const formAdmins = (users, user) => {
  const fullUsers = user ? [...users, user] : users
  return users 
    ? fullUsers.reduce((acc, u) => +u.role !== USER 
      ? [...acc, { ...u, nameValue: u.name && u.surname ? `${u.surname} ${u.name}` : u.username }]
      : acc
    , [])
    : null
}


//! { jam: 1, admin: 2 }

