import { 
  FETCH_PROPERTIES_SUCCESS, FETCH_PROPERTIES_FAILURE,
  CREATE_PROPERTIES_SUCCESS, CREATE_PROPERTIES_FAILURE,
  EDIT_PROPERTIES_SUCCESS, EDIT_PROPERTIES_FAILURE,
  DELETE_PROPERTIES_SUCCESS, DELETE_PROPERTIES_FAILURE,
  CREATE_AREA_SUCCESS,  CREATE_AREA_FAILURE,
  CREATE_SUB_AREA_SUCCESS, CREATE_SUB_AREA_FAILURE, 
  EDIT_AREA_SUCCESS,  EDIT_AREA_FAILURE,
  EDIT_SUB_AREA_SUCCESS, EDIT_SUB_AREA_FAILURE, 
  DELETE_AREA_SUCCESS,  DELETE_AREA_FAILURE,
  DELETE_SUB_AREA_SUCCESS, DELETE_SUB_AREA_FAILURE, 
} from 'r_constants/properties.constants'
      
import { 
  _fetchProperties, _createProperty, _editProperty, _deleteProperty,
  _createArea, _createSubArea, _editArea, _editSubArea, _deleteArea, _deleteSubArea 
} from 'r_services/properties.service'
      
export const fetchProperties = () => async dispatch => {
  try {
    const properties = await _fetchProperties()
    dispatch({ type: FETCH_PROPERTIES_SUCCESS, properties })
  } catch (error) {
    dispatch({ type: FETCH_PROPERTIES_FAILURE, error })
  }
}
      
export const createProperty = (_property) => async dispatch => {
  try {
    const property = await _createProperty(_property)
    dispatch({ type: CREATE_PROPERTIES_SUCCESS, property })
  } catch (error) {
    dispatch({ type: CREATE_PROPERTIES_FAILURE, error })
  }
}
      
export const editProperty = (_property) => async dispatch => {
  try {
    const property = await _editProperty(_property)
    dispatch({ type: EDIT_PROPERTIES_SUCCESS, property })
  } catch (error) {
    dispatch({ type: EDIT_PROPERTIES_FAILURE, error })
  }
}
      
export const deleteProperty = (id) => async dispatch => {
  try {
    const property = await _deleteProperty(id)
    dispatch({ type: DELETE_PROPERTIES_SUCCESS, property })
  } catch (error) {
    dispatch({ type: DELETE_PROPERTIES_FAILURE, error })
  }
}
      

      
export const createArea = (_area) => async dispatch => {
  try {
    const area = await _createArea(_area)
    dispatch({ type: CREATE_AREA_SUCCESS, area })
  } catch (error) {
    dispatch({ type: CREATE_AREA_FAILURE, error })
  }
}
      
export const createSubArea = (_subArea) => async dispatch => {
  try {
    const subArea = await _createSubArea(_subArea)
    dispatch({ type: CREATE_SUB_AREA_SUCCESS, subArea })
  } catch (error) {
    dispatch({ type: CREATE_SUB_AREA_FAILURE, error })
  }
}

      
export const editArea = (_area) => async dispatch => {
  try {
    const area = await _editArea(_area)
    dispatch({ type: EDIT_AREA_SUCCESS, area })
  } catch (error) {
    dispatch({ type: EDIT_AREA_FAILURE, error })
  }
}
      
export const editSubArea = (_subArea) => async dispatch => {
  try {
    const subArea = await _editSubArea(_subArea)
    dispatch({ type: EDIT_SUB_AREA_SUCCESS, subArea })
  } catch (error) {
    dispatch({ type: EDIT_SUB_AREA_FAILURE, error })
  }
}

      
export const deleteArea = (_area) => async dispatch => {
  try {
    const area = await _deleteArea(_area)
    dispatch({ type: DELETE_AREA_SUCCESS, area })
  } catch (error) {
    dispatch({ type: DELETE_AREA_FAILURE, error })
  }
}
      
export const deleteSubArea = (_subArea) => async dispatch => {
  try {
    const subArea = await _deleteSubArea(_subArea)
    dispatch({ type: DELETE_SUB_AREA_SUCCESS, subArea })
  } catch (error) {
    dispatch({ type: DELETE_SUB_AREA_FAILURE, error })
  }
}
      