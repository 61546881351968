import styled from 'styled-components'

const StyledSelector = styled.div`
margin-bottom: 2rem;
.react-colorful{
  width: auto;
  height: 125px;
}

`

const StyledWrapper = styled.div`
  align-items: center;
  justify-content: center;


`


export { StyledSelector, StyledWrapper }
