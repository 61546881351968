import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { checkUsername, resetUsernameCheck, createUser, editUser, deleteUser } from 'r_actions/users.actions'
import { setMode, setSelected } from 'r_actions/noApi.actions'
import { validateUser } from 'helpers/validator'
import UserEditorForm from './UserEditorForm'

const empty = { username: '', mail: '', phone: '', name: '', surname: '', role: 3, id: null, subAreas: [], restrict: [] }


const UsersEditor = () => {
  const dispatch = useDispatch()

  const { selected, mode } = useSelector(s => s.noApi)
  const validUsername = useSelector(s => s.validUsername)
  const categories = useSelector(s => s.categories)
  const clients = useSelector(s => s.clients) || []
  const properties = useSelector(s => s.properties || []) 
 
  const [data, setData] = useState({})
  const [submitted, setSubmitted] = useState(false)

   
  const { id, username, mail, name, surname, role, subAreas, restrict } = data
    
  const onChange = e => { 
    const { name, value } = e.target        
    setData(d => ({ ...d, [name]: value })) 
  }

  const password = clients.length ? clients[0].password : ''
 
  useEffect(() => {
    const invertRights = () => {
      const rightsIds = selected.rights.map(r => +r.id)
      const categoryIds = categories.map(c => +c.id)
      return categoryIds.filter(ci => !rightsIds.includes(ci))
    }
    const _subAreas = properties.reduce((acc, p) => 
      [...acc, ...p.areas.map(a => a.subAreas.map(s => s.id)).flat()]
    , [])

    selected && setData(d => ({ ...d, ...selected, 
      restrict: invertRights(), 
      username: selected.username.substring(selected.username.indexOf('-') + 1) 
    }))
    
    !selected && setData(d => ({ ...d, ...empty, subAreas: _subAreas }))
  }, [selected, categories, properties])
  
 
  const checkName = () => {
    if(!selected || username !== selected.username) dispatch(checkUsername(username))
  }

  useEffect(() => {
    dispatch(resetUsernameCheck())
  }, [mode, dispatch])    

  const { valid, validUser } = validateUser(data, !!selected, validUsername)

  const cleanStuff = () => {
    setData(empty)
    dispatch(setMode('icon'))
    dispatch(setSelected(null))
    setSubmitted(false)
  }


  const onSubmit = () => {
    setSubmitted(true)


    
    const editPackage = { username, mail, name, surname, role, subAreas, restrict }

    if(validUser) {
      id 
        ? dispatch(editUser({ id, editPackage }))
        : dispatch(createUser({ ...data, password, state: 2 }))

      cleanStuff()
    }
  }

  const onDelete = () => {
    dispatch(deleteUser(id))
    cleanStuff()
  }

  
  return <UserEditorForm
    data={data}
    onChange={onChange}
    checkName={checkName}
    onSubmit={onSubmit}
    onDelete={onDelete}
    valid={valid}
    setSubmitted={setSubmitted}
    submitted={submitted}
  />
  
}


export default UsersEditor
