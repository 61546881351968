import React from 'react'
import PropTypes from 'prop-types'
import { StyledSelectable } from '../Styles'
import { Square, CheckSquare } from 'react-feather'


const Selectable = ({ subArea, selected, onChange }) => {

  const { id, title } = subArea


  return <StyledSelectable className="columns" onClick={() => onChange(id)}>

    <div className="column is-10">
      {title}
    </div>


    <div className="column">
      {
        selected
          ? <CheckSquare color="#6cba2c"/>
          : <Square color="#050505a6"/>
      }

    </div>

  </StyledSelectable>


        
}

Selectable.propTypes = {
  subArea: PropTypes.object, 
  selected: PropTypes.bool, 
  onChange: PropTypes.func, 
}

export default Selectable
