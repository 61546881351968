export const FETCH_RECIPIENTS_SUCCESS = 'FETCH_RECIPIENTS_SUCCESS'
export const FETCH_RECIPIENTS_FAILURE = 'FETCH_RECIPIENTS_FAILURE'

export const CREATE_RECIPIENTS_SUCCESS = 'CREATE_RECIPIENTS_SUCCESS'
export const CREATE_RECIPIENTS_FAILURE = 'CREATE_RECIPIENTS_FAILURE'

export const EDIT_RECIPIENTS_SUCCESS = 'EDIT_RECIPIENTS_SUCCESS'
export const EDIT_RECIPIENTS_FAILURE = 'EDIT_RECIPIENTS_FAILURE'

export const DELETE_RECIPIENTS_SUCCESS = 'DELETE_RECIPIENTS_SUCCESS'
export const DELETE_RECIPIENTS_FAILURE = 'DELETE_RECIPIENTS_FAILURE'

