import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import List from './list/List'
import EditorPanel from './EditorPanel'
import { GlassColumn } from './Styles'

const SectionHandler = ({ list }) => {

  const location = useLocation()

  const [search, setSearch] = useState('')

  const path = location.pathname.replace('/', '')

  const onSearch = (e) => setSearch(e.target.value)

  const filteredList = search 
    ? list.filter(l => l.nameValue.toLowerCase().includes(search)) 
    : list

  return (
    
    <GlassColumn className="columns">

      <List list={filteredList} onSearch={onSearch} search={search} path={path}/>

      <EditorPanel path={path}/>
        
    </GlassColumn>
    
  )
}

SectionHandler.propTypes = {
  list: PropTypes.array,
}

export default SectionHandler
