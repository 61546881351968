import initialState from './initialState'
import { 
  FETCH_CLIENTS_SUCCESS, FETCH_CLIENTS_FAILURE,
  CREATE_CLIENTS_SUCCESS, CREATE_CLIENTS_FAILURE,
  EDIT_CLIENTS_SUCCESS, EDIT_CLIENTS_FAILURE,
  DELETE_CLIENTS_SUCCESS, DELETE_CLIENTS_FAILURE
} from 'r_constants/clients.constants'

export default function clients(clients = initialState.clients, action) {
  switch (action.type) {
      
  case FETCH_CLIENTS_SUCCESS:
    return formClients(action.clients)

  case FETCH_CLIENTS_FAILURE:
    return clients

  case CREATE_CLIENTS_SUCCESS:
    return formClients(clients, action.client)

  case CREATE_CLIENTS_FAILURE:
    return clients

  case EDIT_CLIENTS_SUCCESS:
    return clients.map(c => 
      +action.client.id === +c.id 
        ? { ...action.client, nameValue: action.client.title }
        : c
    )
  
  case EDIT_CLIENTS_FAILURE:
    return clients

  case DELETE_CLIENTS_SUCCESS:
    return clients.filter(c => +c.id !== +action.client.id )

  case DELETE_CLIENTS_FAILURE:
    return clients

  default:
    return clients
  }
}


const formClients = (clients, client) => {
  const fullClients = client ? [...clients, client] : clients
  return clients
    ? fullClients.reduce((acc, c) => [...acc, { ...c, nameValue: c.title }]
      , [])
    : null
}