import toast from 'helpers/toast'

import { LOGIN_FAILURE, LOGIN_SUCCESS, 
  LOGOUT_SUCCESS, LOGOUT_FAILURE,
  AUTH_SUCCESS, AUTH_ERROR,

} from 'r_constants/auth.constants'

import { _login, _logout, _authenticate } from '../r_services/auth.service'
import { setUser } from '@sentry/browser'


export const login = (user, wrongPass, wrongUser) => async dispatch => {
  try {
    const payload = await _login(user)

    setUser(payload)
    dispatch({ type: LOGIN_SUCCESS, payload })
  } catch (error) {
    const { message } = error
    if(message === 'Password incorrect') toast('error', wrongPass)
    if(message === 'Invalid login') toast('error', wrongUser)
    dispatch({ type: LOGIN_FAILURE, error })
  }
}

export const authenticate = () => async dispatch => {
  try {
    const payload = await _authenticate()
    dispatch({ type: AUTH_SUCCESS, payload })
  } catch (error) {
    dispatch({ type: AUTH_ERROR, error })
  }
}


export const logout = expired => async dispatch => {
  try {
    const status = await _logout()
    dispatch({ type: LOGOUT_SUCCESS, status, expired })
  } catch (error) {
    dispatch({ type: LOGOUT_FAILURE, error })
  }

}

