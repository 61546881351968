import initialState from './initialState'
import { 
  FETCH_CONTACTS_SUCCESS, FETCH_CONTACTS_FAILURE,
  CREATE_CONTACTS_SUCCESS, CREATE_CONTACTS_FAILURE,
  EDIT_CONTACTS_SUCCESS, EDIT_CONTACTS_FAILURE,
  DELETE_CONTACTS_SUCCESS, DELETE_CONTACTS_FAILURE

} from 'r_constants/contacts.constants'


export default function contacts(contacts = initialState.contacts, action) {
  switch (action.type) {
      
  case FETCH_CONTACTS_SUCCESS:
    return formContacts(action.contacts)

  case FETCH_CONTACTS_FAILURE:
    return contacts

  case CREATE_CONTACTS_SUCCESS:
    return formContacts(contacts, action.contact)

  case CREATE_CONTACTS_FAILURE:
    return contacts

  case EDIT_CONTACTS_SUCCESS:
    return contacts.map(c => 
      +action.contact.id === +c.id 
        ? { ...action.contact, nameValue: action.contact.name }
        : c
    )

  case EDIT_CONTACTS_FAILURE:
    return contacts

  case DELETE_CONTACTS_SUCCESS:
    return contacts.filter(c => +c.id !== +action.contact.id )

  case DELETE_CONTACTS_FAILURE:
    return contacts

  default:
    return contacts
  }
}


const formContacts = (contacts, contact) => {
  const fullContacts = contact ? [...contacts, contact] : contacts
  return contacts
    ? fullContacts.reduce((acc, c) => [...acc, { ...c, nameValue: c.name }]
      , [])
    : null
}