import { CATEGORIES, THERAPIES } from './feathers'

export const _fetchCategories = async () => {
  const { data } = await CATEGORIES.find()
  return data
}

export const _createCategory = async (_category) => {
  const category = await CATEGORIES.create(_category)
  return category
}

export const _editCategory = async ({ id, data }) => {
  const category = await CATEGORIES.patch(id, data)
  return category
}

export const _deleteCategory = async (id) => {
  const category = await CATEGORIES.remove(id)
  return category
}


export const _createTherapy = async (_therapy) => {
  const therapy = await THERAPIES.create(_therapy)
  return therapy
}

export const _editTherapy = async ({ id, data }) => {
  const therapy = await THERAPIES.patch(id, data)
  return therapy
}

export const _deleteTherapy = async (id) => {
  const therapy = await THERAPIES.remove(id)
  return therapy
}

