import styled from 'styled-components'

const StyledContainer = styled.article`
  user-select: none;
  overflow: hidden;
`

const StyledScrollable = styled.div`
  max-height: 35rem;
  overflow-y: auto;
`

const StyledProperty = styled.div`
  padding: 0.8rem;
  &:nth-child(odd) {
    background-color: #ededed;
  }

  &:hover {
    cursor: pointer;
  }
`

const StyledAreas = styled.div`
  padding: 0 0.5rem;
`

const StyledSelectable = styled.div`
  border-bottom: 1px solid #ededed;
  padding: 0 1rem;
`

const StyledElement = styled.div`
  line-height: 25px;
  border-bottom: 1px solid #ededed;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:active {
    cursor: pointer;
    background-color: #ededed;
  }
`

export {
  StyledContainer,
  StyledProperty,
  StyledScrollable,
  StyledAreas,
  StyledSelectable,
  StyledElement
}
