import { DISEASES } from './feathers'

export const _fetchDiseases = async () => {
  const { data } = await DISEASES.find()
  return data
}

export const _createDisease = async (_disease) => {
  const disease = await DISEASES.create(_disease)
  return disease
}

export const _editDisease = async ({ id, data }) => {
  const disease = await DISEASES.patch(id, data)
  return disease
}

export const _deleteDisease = async (id) => {
  const disease = await DISEASES.remove(id)
  return disease
}


