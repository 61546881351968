import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import imageCompression from 'browser-image-compression'
import toast from 'helpers/toast'
import { Upload } from 'react-feather'
import { StyledUploader, StyledPreview, Image } from './Style'
import feathersAPI from 'helpers/api_config'
import PDFViewer from './PDF/PDFViewer'


const IMAGE_TYPES = ['image/x-png', 'image/jpeg', 'image/png']


const FileUpload = ({ intl, label, file, onChange, viewOnly, property='file', additionalMime  }) => {

  const imageRef = useRef(null)
  
  const onRemove = () => onChange({ target: { name: property, value: null } })

  const handleFileChange = async e => {

    if (!e.target.files.length) return

    let [buffer] = e.target.files

    let mimetype = buffer.type

    const supportedMime =  [...IMAGE_TYPES, additionalMime]

    const notSupported = !supportedMime.includes(mimetype)
      
    const doError = (message) => {
      onChange( { target: { name: property, value: null } })
      return toast('error', message)
    }
    
    if(notSupported) return doError(intl.formatMessage({ id: 'invalid-file' }))
    
    const options = { maxSizeMB: 2,maxWidthOrHeight: 1920, useWebWorker: true }

    const compressed = IMAGE_TYPES.includes(mimetype) ? await imageCompression(buffer, options) : buffer

    const filePreview = URL.createObjectURL(buffer)

    const type = mimetype.split('/')[1]

    let reader = new FileReader()
    reader.readAsDataURL(buffer)
    reader.onloadend = () => onChange({ target: { name: property, value: { buffer: compressed, mimetype, filePreview, type } } })
  }

  const source = file && (typeof file === 'string' ? `${feathersAPI}/api/uploads/${file}` : file.filePreview)

  const isPDF = file && (typeof file === 'string' 
    ? file.substring(file.lastIndexOf('.') + 1) === 'pdf'
    : file.mimetype === 'application/pdf')


  const handleImageSize = ( { target } ) => {
    const { naturalWidth, naturalHeight } = target

    typeof file !== 'string' && onChange({ target: { name: property, value: { ...file, 
      width: naturalWidth, 
      height: naturalHeight 
    } } } )
  }

 
  return (
    <div>

      {!viewOnly && 
        <StyledUploader className="file has-name is-right is-fullwidth">

          <label className="file-label">

            <input 
              className="file-input" 
              type="file" 
              accept={`image/jpeg,image/png,image/x-png,${additionalMime}`}
              onChange={handleFileChange}
              onClick={e => {e.target.value = null}}
            />

            <span className="file-cta">
              <span className="file-icon"> <Upload/> </span>
              <span className="file-label"> {label} </span>
            </span>

            <span className="file-name"> 
              {
                file 
                  ? typeof file === 'string' 
                    ? file.split('/')[1]
                    : file.buffer.name 
                  : ''
              }
            </span>

          </label>

        </StyledUploader>
      }
      { 
        file &&
          <StyledPreview>

            { isPDF
              ? <PDFViewer path={source}/>
              : <Image>
                <img 
                  src={source} 
                  alt="" 
                  ref={imageRef}
                  onLoad={handleImageSize}
                />
              </Image>
            }

            {
              !viewOnly&&
                <button className="button is-fullwidth is-danger" onClick={onRemove} >
                  {intl.formatMessage({ id: 'remove-file' })}
                </button>
            }
           
          </StyledPreview>
      }
      
    </div>
   
  )
}

FileUpload.propTypes = {
  label: PropTypes.string, 
  file: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]), 
  onChange: PropTypes.func, 
  viewOnly: PropTypes.bool,
  property: PropTypes.string,
  additionalMime: PropTypes.string,
}

export default injectIntl(FileUpload)
