import styled from 'styled-components'

const SytledUpperWrapper = styled.div`
  margin-bottom: 12px;
`

const StyledWrappper = styled.div`
  width: 100%;

  .dropdown-menu {
    width: 100%;
  }
`

const StyledElement = styled.a`
  width: 100%;
`

const StyledTrigger = styled.div`
  width: 100%;
`

const StyledMenu = styled.div`
  max-height: 20vh;
  overflow-y: auto;
`

export { StyledWrappper, SytledUpperWrapper, StyledElement, StyledTrigger, StyledMenu }
