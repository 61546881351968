import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { StyledElement } from './Styles'
import { User } from 'react-feather'


const ListElement = ({ element, onSelect, path }) => {

  const { id } = useSelector(s => s.auth)
  const { selected } = useSelector(s => s.noApi)

  const isSelected = selected && +selected.id === +element.id

  const value = (path === 'admins' || path === 'users') && +element.id === +id
    ? <>
      <User size={20} />
      {element.nameValue} 
    </>
    : element.nameValue
  

  return  <StyledElement onClick={() => onSelect(element)} isSelected={!!isSelected}>
    {value}
  </StyledElement>

}

ListElement.propTypes = {
  element: PropTypes.object,
  onSelect: PropTypes.func,
  path: PropTypes.string,
}

export default ListElement



