import React from 'react'
import PropTypes from 'prop-types'
import { StyledInput } from './Styles'
import { User, Lock, AtSign, Phone, Smile, Hash, Droplet, UserPlus, Edit2 } from 'react-feather'


const Input = ({ 
  placeholder, 
  type, 
  value, 
  isValid, 
  onChange, 
  invalidText, 
  hasIcon=true, 
  viewOnly=false, 
  onBlur=()=>{}, 
  name=null, 
  addotionalStyle={},
  pattern 
}) => {

  if(type.includes('Color')) value = '#' + value.split('#').join('')

  const icon = { 
    username: <User/>, 
    password: <Lock/>, 
    _password: <Lock/>, 
    mail: <AtSign/>,
    phone: <Phone/>, 
    client: <Smile/>, 
    clientNumber: <Hash/>, 
    color: <Droplet fill={value} strokeWidth={1}/>, 
    name: <UserPlus/>, 
    surname: <UserPlus/>, 
    description: <Edit2/>, 
    title: <Edit2/>, 
  }

  const style = {
    pointerEvents: viewOnly ? 'none' : 'all',
  }

  const selectedIcon = type.includes('Color') ? icon.color : icon[type]


  return (    
    <div className="field">

      <p className="control has-icons-left has-icons-right">

        <StyledInput 
          className="input"
          type={type} 
          name={name || type}
          placeholder={placeholder} 
          value={value ? value : ''} 
          onChange={onChange}
          style={{ ...style, ...addotionalStyle }}  
          onBlur={() => onBlur()}  
          pattern={pattern}      
        />

        { 
          hasIcon && <span className="icon is-small is-left">
            <i className={'fas'}>{selectedIcon}</i>
          </span>
        }
        
      </p>

      {value && <p className="help">{placeholder}</p>}
      {!isValid && <p className="help is-danger">{invalidText}</p>}

    </div>
  )
   
}


Input.propTypes = {
  placeholder: PropTypes.string, 
  type: PropTypes.string,
  value: PropTypes.string,
  isValid: PropTypes.bool, 
  onChange: PropTypes.func, 
  invalidText: PropTypes.string, 
  hasIcon: PropTypes.bool,
  viewOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  pattern: PropTypes.string,
}

  
export default Input