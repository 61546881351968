import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import SubAreas from '../subareas/SubAreas'
import ControlButtons from '../ControlButtons'

import { SytledTitelRow, StyledTitle } from '../Styles'
import { createArea, editArea, deleteArea } from 'r_actions/properties.actions'

const AreasEditor = ({ intl, selected, selectArea, viewOnly }) => {

  const dispatch = useDispatch()

  const { selected: selectedProperty } = useSelector(s => s.noApi)

  const { title = '', id = null , subAreas } = selected

  const [name, setName] = useState(title)
 

  useEffect(() => {
    setName(title)
  }, [selected, title])

  const onSubmit = () => {
    const data = { title: name, subAreas, property: selectedProperty.id }
    if(id) return dispatch(editArea({ id, data }))
    dispatch(createArea(data))
  }

  const onDelete = () => {
    id && dispatch(deleteArea(id))
    selectArea(null)
  }

  return <div>

    <SytledTitelRow className="columns">

      <StyledTitle className="column">

        <input 
          className="input m-0" 
          placeholder={intl.formatMessage({ id: 'properties_areas_title' })}
          type="text" 
          style={{ pointerEvents: viewOnly ? 'none' : 'all' }}
          value={name} 
          onChange={e => setName(e.target.value)}
        />
   
        {name && <p className="help">{intl.formatMessage({ id: 'properties_areas_title' })}</p>}

      </StyledTitle>

      {!viewOnly &&

        <ControlButtons 
          disabled={!name || title===name} 
          onSubmit={onSubmit} 
          onDelete={onDelete} />

      }

    </SytledTitelRow>


    {selected.id &&  

    <SubAreas 
      subAreas={subAreas} 
      areaId={id}
      viewOnly={viewOnly}
    />

    }


  </div>
}

AreasEditor.propTypes = {
  selected: PropTypes.object,
  selectArea: PropTypes.func,
  viewOnly: PropTypes.bool,
}

export default injectIntl(AreasEditor)
