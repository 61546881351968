import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Users } from 'react-feather'
import EditorButtons from 'components/WorkSpace/editorButtons/EditorButtons'
import PropertiesList from './subSelector/property/PropertiesList'
import RightsSelector from './rightsSelector/RightsSelector'
import { StyledColumn, StyledRightsButton } from 'Styles'

import Input from 'components/common/input/Input'
// import Dropdown from 'components/common/Dropdown' //TODO: preselector

const UserEditorForm = ({ intl, data, onChange, checkName, onSubmit, onDelete, valid, setSubmitted, submitted }) => {

  const { id, username, mail, name, surname, subAreas, restrict, phone } = data
  
  //TODO: addicon && merge with admins later
  const { mode } = useSelector(s => s.noApi)
  const { id: myId } = useSelector(s => s.auth)
  const validUsername = useSelector(s => s.validUsername)

  const [rightView, setRightView] = useState(0)

  const viewOnly = mode === 'view' ? true : false

  return mode === 'icon' 

    ? <Users size={48} color="#949494"/>

    : <div className="columns">

      <StyledColumn className="column">
         
        <Input
          placeholder={intl.formatMessage({ id: 'username' })}
          type="username"
          value={username}
          isValid={!submitted || valid.username} 
          onChange={onChange}
          invalidText={validUsername 
            ? intl.formatMessage({ id: 'input-username' })
            : intl.formatMessage({ id: 'input-username_taken' })
          }
          viewOnly={viewOnly}
          onBlur={checkName}
        
        />   

       
        <Input
          placeholder={intl.formatMessage({ id: 'mail' })}
          type="mail"
          value={mail}
          isValid={!submitted || valid.mail} 
          onChange={onChange}
          invalidText={ !!mail
            ? intl.formatMessage({ id: 'input-mail_invalid' })
            : intl.formatMessage({ id: 'input-mail' })
          }
          viewOnly={viewOnly}
        
        />   


        <Input
          placeholder={intl.formatMessage({ id: 'users_phone' })}
          type="phone"
          value={phone}
          isValid={true} 
          onChange={onChange}
          viewOnly={viewOnly}
        
        />  

        <Input
          placeholder={intl.formatMessage({ id: 'name' })}
          type="name"
          value={name}
          isValid={!submitted || valid.name} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'input-name' })}
          viewOnly={viewOnly}
        
        />  

        <Input
          placeholder={intl.formatMessage({ id: 'surname' })}
          type="surname"
          value={surname}
          isValid={!submitted || valid.surname} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'input-surname' })}
          viewOnly={viewOnly}
        
        />   


      </StyledColumn>

      <StyledColumn className="column">

        <StyledRightsButton className="button is-primary is-fullwidth" onClick={() => setRightView(+!rightView)}>
          {intl.formatMessage({ id: `users_rights_button_${rightView}` })}
        </StyledRightsButton>

        {
          rightView 
            ? <RightsSelector selected={restrict} onChange={onChange}/>
            : <PropertiesList onChange={onChange} subAreas={subAreas}/>

        }
        
      </StyledColumn>

      <EditorButtons onSubmit={onSubmit} canDelete={myId !== id} onDelete={onDelete} reset={() => setSubmitted(false)}/>
    </div>

  
}

UserEditorForm.propTypes = {
  data: PropTypes.object, 
  onChange: PropTypes.func, 
  checkName: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  valid: PropTypes.object,
  setSubmitted: PropTypes.func,
}


export default injectIntl(UserEditorForm)
