import { 
  FETCH_ADVISORS_SUCCESS, FETCH_ADVISORS_FAILURE,
  CREATE_ADVISORS_SUCCESS, CREATE_ADVISORS_FAILURE,
  EDIT_ADVISORS_SUCCESS, EDIT_ADVISORS_FAILURE,
  DELETE_ADVISORS_SUCCESS, DELETE_ADVISORS_FAILURE
} from 'r_constants/advisor.constants'
      
import { _fetchAdvisors, _createAdvisor, _editAdvisor, _deleteAdvisor } from 'r_services/advisor.service'
      
export const fetchAdvisors = () => async dispatch => {
  try {
    const advisors = await _fetchAdvisors()
    dispatch({ type: FETCH_ADVISORS_SUCCESS, advisors })
  } catch (error) {
    dispatch({ type: FETCH_ADVISORS_FAILURE, error })
  }
}
      
export const createAdvisor = (_advisor) => async dispatch => {
  try {
    const advisor = await _createAdvisor(_advisor)
    dispatch({ type: CREATE_ADVISORS_SUCCESS, advisor })
  } catch (error) {
    dispatch({ type: CREATE_ADVISORS_FAILURE, error })
  }
}
      
export const editAdvisor = (_advisor) => async dispatch => {
  try {
    const advisor = await _editAdvisor(_advisor)
    dispatch({ type: EDIT_ADVISORS_SUCCESS, advisor })
  } catch (error) {
    dispatch({ type: EDIT_ADVISORS_FAILURE, error })
  }
}
      
export const deleteAdvisor = (id) => async dispatch => {
  try {
    const advisor = await _deleteAdvisor(id)
    dispatch({ type: DELETE_ADVISORS_SUCCESS, advisor })
  } catch (error) {
    dispatch({ type: DELETE_ADVISORS_FAILURE, error })
  }
}
      
      
      