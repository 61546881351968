import { CLIENTS } from './feathers'

export const _fetchClients = async () => {
  const { data } = await CLIENTS.find()
  return data
}

export const _createClient = async (_client) => {
  const client = await CLIENTS.create(_client)
  return client
}

export const _editClient = async ({ id, data }) => {

  const { logo } = data

  const newData = { ...data, logo: logo 
    ? typeof logo === 'string'
      ? null
      : logo
    : 'remove'
  }

  const client = await CLIENTS.patch(id, newData)
  return client
}

export const _deleteClient = async (id) => {
  const client = await CLIENTS.remove(id)
  return client
}


