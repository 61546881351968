import styled from 'styled-components'

const LoginFormStyled = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;


  button {
    background-color: #67b437;
  }
`
const Image = styled.figure`
padding: 0px 50px 30px;

`

const Button = styled.button`
  margin-top: 20px;
`

export { LoginFormStyled, Image, Button }
