import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { logout } from 'r_actions/auth.actions'
import { setSelected, setMode } from 'r_actions/noApi.actions'
import NavigationBar from './NavigationBar'

//! { jam: 1, admin: 2 }
const routes = {
  1: ['admins', 'clients', 'contacts', 'recipients', 'boxes', 'categories', 'diseases', 'advisors', 'orders'],
  2: ['admins', 'users', 'properties'],
}

const Navigation = () => {

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' })

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { role } = useSelector((s) => s.auth)

  const onSelect = (name) => {
    dispatch(setSelected(null))
    dispatch(setMode('icon'))
    history.push(`/${name}`)
  }
  
  const logMeOut = () => dispatch(logout())

  const path = location.pathname 
    ? location.pathname.replace('/', '')
    : location.location.pathname.replace('/', '')

  useEffect(() => {
    if(!routes[role].includes(path)) history.push(`/${routes[role][0]}`)
  }, [path, history, role])

  return <NavigationBar
    logout={logMeOut}
    routes={routes[role]}
    path={path}
    onSelect={onSelect}
    isSmallScreen={isSmallScreen}
  />
  
}

export default Navigation
