import initialState from './initialState'
import { 
  FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE,
  CREATE_CATEGORIES_SUCCESS, CREATE_CATEGORIES_FAILURE,
  EDIT_CATEGORIES_SUCCESS, EDIT_CATEGORIES_FAILURE,
  DELETE_CATEGORIES_SUCCESS, DELETE_CATEGORIES_FAILURE,
  CREATE_THERAPIES_SUCCESS, CREATE_THERAPIES_FAILURE,
  EDIT_THERAPIES_SUCCESS, EDIT_THERAPIES_FAILURE,
  DELETE_THERAPIES_SUCCESS, DELETE_THERAPIES_FAILURE,
} from 'r_constants/categories.constants'

const CAREBOX = 5

export default function categories(categories = initialState.categories, action) {
  switch (action.type) {
      
  case FETCH_CATEGORIES_SUCCESS:
    return formCategories(action.categories)

  case CREATE_CATEGORIES_SUCCESS:
    return formCategories(categories, action.category)

  case EDIT_CATEGORIES_SUCCESS:
    return categories.map(c => 
      +action.category.id === +c.id 
        ? { ...action.category, nameValue: action.category.title }
        : c
    )

  case DELETE_CATEGORIES_SUCCESS:
    return categories.filter(c => +c.id !== +action.category.id )



  case CREATE_THERAPIES_SUCCESS:
    return categories.map(c => +c.id === +action.therapy.category
      ? { ...c, therapies: [...c.therapies, action.therapy] }
      : c
    )

  case EDIT_THERAPIES_SUCCESS:
    return categories.map(c => +c.id === +action.therapy.category
      ? { ...c, therapies: c.therapies.map(th => +th.id === +action.therapy.id
        ? action.therapy
        : th
      ) }
      : c
    )

  case DELETE_THERAPIES_SUCCESS:
    return categories.map(c => +c.id === +action.therapy.category
      ? { ...c, therapies: c.therapies.filter(th => +th.id !== +action.therapy.id) }
      : c
    )



  case EDIT_CATEGORIES_FAILURE:
    return categories

  case CREATE_CATEGORIES_FAILURE:
    return categories

  case FETCH_CATEGORIES_FAILURE:
    return categories

  case DELETE_CATEGORIES_FAILURE:
    return categories

  case CREATE_THERAPIES_FAILURE:
    return categories

  case EDIT_THERAPIES_FAILURE:
    return categories

  case DELETE_THERAPIES_FAILURE:
    return categories

  default:
    return categories
  }
}


const formCategories = (categories, category) => {
  const fullCategories = category ? [...categories, category] : categories
  return categories
    ? fullCategories.reduce((acc, c) => [...acc, { ...c, nameValue: c.title }]
      , [])
    : null
}