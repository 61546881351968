import React from 'react'
import PropTypes from 'prop-types'
import AreaList from '../area/AreaLIst'
import { StyledProperty } from '../Styles'
import { ChevronDown, ChevronUp } from 'react-feather'

//TODO: selected symbol

const TitleElement = ({ element, onChange, subAreas, setOpen, open }) => {

  const { id, title, areas } = element

  const length = !!areas.length

  const onOpen = () => length && setOpen(+open === +id ? null : id)

  const hasSelected = (areas && subAreas) && !!areas.map(a => a.subAreas).flat().find(f => subAreas.find(sa => +sa === +f.id)) 

  const color = hasSelected ? '#6cba2c' : '#050505a6'
    

  return <StyledProperty>
      
    <div className="columns"  onClick={onOpen} >

      <div className="column is-10">
        {title}
      </div>


      { length &&  
        <div className="column">
      
          { +open === +id
            ? <ChevronUp color={color}/>
            : <ChevronDown color={color}/>
          }
        </div>
      }

    </div>

    { +open === +id && 
      <AreaList
        areas={areas}
        onChange={onChange}
        subAreas={subAreas}
      />
    }


  </StyledProperty>
        
}

TitleElement.propTypes = {
  element: PropTypes.object,
  onChnage: PropTypes.func,
  subAreas: PropTypes.array,
  setOpen: PropTypes.func,
  open: PropTypes.number,
}

export default TitleElement

