import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import ListElement from './ListElement'
import SearchField from './SearchField'

import translate from 'intl/translate'
import { setMode, setSelected, setSelectedBackup } from 'r_actions/noApi.actions'

import { GlassList } from '../Styles'
import { StyledPanel, StyledScrollBlock, StyledCreateButton, StyledPanelHeading } from './Styles'

const List = ({ list = [], onSearch, search, path }) => {
  const dispatch = useDispatch()

  const onCreate = () => {
    dispatch(setSelected(null))
    dispatch(setSelectedBackup(null))
    dispatch(setMode('edit'))
  }

  const onSelect = (chosen) => {
    dispatch(setSelected(chosen))
    dispatch(setSelectedBackup(chosen))
    dispatch(setMode('view'))
  }

  return (
    <GlassList className="column">

      <StyledPanel className="panel">

        <StyledPanelHeading className="panel-heading">
          {translate({ id: `title.${path}` })}
        </StyledPanelHeading>

        <SearchField onSearch={onSearch} search={search}/>

        <StyledScrollBlock>

          {
            list.length 
              ? list.map((l) => <ListElement key={l.id} element={l} onSelect={onSelect} path={path}/>)
              : <div style={{ textAlign: 'center', padding: 10 }}>{translate({ id: 'none' })}</div>
          }

        </StyledScrollBlock>

        <StyledCreateButton className="panel-block">
          <button 
            className="button is-primary is-fullwidth"
            onClick={onCreate}
          >
            {translate({ id: `create.new.${path}` })}
          </button>
        </StyledCreateButton>

      </StyledPanel>

    </GlassList>
  )
}

List.propTypes = {
  list: PropTypes.array,
  search: PropTypes.string,
  onSearch: PropTypes.func,
  path: PropTypes.string,
}

export default List
