import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AreaElement from './AreaElement'
import { StyledAreas } from '../Styles'



const AreaList = ({ areas, onChange, subAreas }) => {

  const [open, setOpen] = useState(null)
    
  return <StyledAreas>

    {
      areas.map(a => 
        <AreaElement 
          key={a.id}
          element={a} 
          open={open}
          setOpen={setOpen}
          onChange={onChange}
          subAreas={subAreas}
        />
      )
    }

  </StyledAreas>
        
}

AreaList.propTypes = {
  areas: PropTypes.array,
  onChange: PropTypes.func,
  subAreas: PropTypes.array,
}

export default AreaList

