import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { setMode } from 'r_actions/noApi.actions'
import DeleteModal from './DeleteModal'

const Edit = ({ intl, canDelete, onDelete }) => {

  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
 
  const onEdit = () => dispatch(setMode('edit'))


  return <>
  
    { modal && <DeleteModal onDelete={onDelete} onCancel={() => setModal(false)} /> }

    <button
      className="button is-fullwidth is-primary column"
      onClick={onEdit}
    >
      {intl.formatMessage({ id: 'edit' })}
    </button>

    {
      canDelete && (
        <>
          <div className="is-1 column" />

          <button
            className="button is-fullwidth is-danger column"
            onClick={() => setModal(true)}
          >
            {intl.formatMessage({ id: 'delete' })}
          </button>
        </>
      )
    }
  </>

}

     

Edit.propTypes = {
  canDelete: PropTypes.bool, 
  onDelete: PropTypes.func,
}

export default injectIntl(Edit)
