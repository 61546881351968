import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionHandler from 'components/WorkSpace/SectionHandler'
import { fetchUsers } from 'r_actions/users.actions'
import { fetchProperties } from 'r_actions/properties.actions'
import { fetchCategories } from 'r_actions/categories.actions'
import { fetchClients } from 'r_actions/clients.actions'

const Users = () => {
  const dispatch = useDispatch()

  const users = useSelector((s) => s.users) || []

  useEffect(() => {
    dispatch(fetchUsers())
    dispatch(fetchProperties())
    dispatch(fetchCategories())
    dispatch(fetchClients())
  }, [dispatch])


  return (
    users && (

      <SectionHandler
        list={users}
      />
      
    )
  )
}
//TODO: loading

Users.propTypes = {}

export default Users
