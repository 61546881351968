import { CONTACTS } from './feathers'

export const _fetchContacts = async () => {
  const { data } = await CONTACTS.find()
  return data
}

export const _createContact = async (_contact) => {
  const contact = await CONTACTS.create(_contact)
  return contact
}

export const _editContact = async ({ id, data }) => {

  const { image } = data

  const newData = { ...data, image: image 
    ? typeof image === 'string'
      ? null
      : image
    : 'remove'
  }
  const contact = await CONTACTS.patch(id, newData)
  return contact
}

export const _deleteContact = async (id) => {
  const contact = await CONTACTS.remove(id)
  return contact
}



