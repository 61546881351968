import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { IntlProvider, LOCALES } from './intl'

import App from './App'
import reportWebVitals from './reportWebVitals'

import configureStore from  './helpers/store'

if (
  process.env.NODE_ENV === 'production' //&&
  // process.env.REACT_APP_SENTRY_RELEASE
) {
  console.log('sentryInit')
  Sentry.init({
    dsn: 'https://e3327f8374954615964846a9b33330ad@sentry.casenio.de/33',
    // release: process.env.REACT_APP_SENTRY_RELEASE
  })
}


const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider locale={LOCALES.GERMAN}>
      <App />
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()


//TODO => SENTRY => HELPERS