import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Grid } from 'react-feather'
import { StyledColumn } from 'Styles'
import TherapyEditor from './therapyEditor/TherapyEditor'
import EditorButtons from 'components/WorkSpace/editorButtons/EditorButtons'
import Dropdown from 'components/common/dropdown/Dropdown' //TODO: preselector

import Input from 'components/common/input/Input'


const ClientEditorForm = ({ intl, submitted, data, onChange, onSubmit, onDelete, setSubmitted }) => {

  const { title, therapies, id, recipient } = data

  const { mode } = useSelector(s => s.noApi)
  const recipients = useSelector(s => s.recipients) || []

  const viewOnly = mode === 'view' ? true : false

  return mode === 'icon'  

    ? <Grid size={48} color="#949494"/>

    : <div className="columns">

      <StyledColumn className="column">

        <Input
          placeholder={intl.formatMessage({ id: 'categories_title' })}
          type="title"
          value={title}
          isValid={!submitted || !!title} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'categories_title_invalid' })}
          viewOnly={viewOnly}
        
        />  

        <Dropdown
          options={recipients}
          property="mail"
          type="recipient"
          value={recipient}
          onChange={onChange}
          placeholder = {intl.formatMessage({ id: 'categories_add_recipient' })}
        />

      </StyledColumn>

      <StyledColumn className="column" >

        <TherapyEditor
          therapies={therapies}
          viewOnly={viewOnly}
          categoryId={id}
        />

      </StyledColumn>

      <EditorButtons onSubmit={onSubmit} canDelete={true} onDelete={onDelete} reset={() => setSubmitted(false)}/>
    </div>

  
}

ClientEditorForm.propTypes = {
  submitted: PropTypes.bool,
  data: PropTypes.object, 
  onChange: PropTypes.func, 
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  valid: PropTypes.object,
  setSubmitted: PropTypes.func,
}



export default injectIntl(ClientEditorForm)
