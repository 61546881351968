import { 
  FETCH_CONTACTS_SUCCESS, FETCH_CONTACTS_FAILURE,
  CREATE_CONTACTS_SUCCESS, CREATE_CONTACTS_FAILURE,
  EDIT_CONTACTS_SUCCESS, EDIT_CONTACTS_FAILURE,
  DELETE_CONTACTS_SUCCESS, DELETE_CONTACTS_FAILURE,

} from 'r_constants/contacts.constants'
    
import { _fetchContacts, _createContact, _editContact, _deleteContact } from 'r_services/contacts.service'
    
export const fetchContacts = () => async dispatch => {
  try {
    const contacts = await _fetchContacts()
    dispatch({ type: FETCH_CONTACTS_SUCCESS, contacts })
  } catch (error) {
    dispatch({ type: FETCH_CONTACTS_FAILURE, error })
  }
}
  
export const createContact = (_contact) => async dispatch => {
  try {
    const contact = await _createContact(_contact)
    dispatch({ type: CREATE_CONTACTS_SUCCESS, contact })
  } catch (error) {
    dispatch({ type: CREATE_CONTACTS_FAILURE, error })
  }
}
  
export const editContact = (_contact) => async dispatch => {
  try {
    const contact = await _editContact(_contact)
    dispatch({ type: EDIT_CONTACTS_SUCCESS, contact })
  } catch (error) {
    dispatch({ type: EDIT_CONTACTS_FAILURE, error })
  }
}
  
export const deleteContact = (id) => async dispatch => {
  try {
    const contact = await _deleteContact(id)
    dispatch({ type: DELETE_CONTACTS_SUCCESS, contact })
  } catch (error) {
    dispatch({ type: DELETE_CONTACTS_FAILURE, error })
  }
}
  
  

    
  