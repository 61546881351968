import { animated } from 'react-spring'
import styled from 'styled-components'

const AppStyled = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgb(2, 0, 36);
  background: linear-gradient(
    141deg,
    rgba(2, 0, 36, 0.9108018207282913) 12%,
    rgba(53, 93, 209, 0.9220063025210083) 58%,
    rgba(87, 212, 54, 0.8939950980392157) 84%
  );
`

const GlassTileStyled = styled(animated.div)`
  will-change: min-height, width;
  max-height: 95vh;
  min-width: 380px;
  background: white;
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, ${p => p.opacityleft}),
    rgba(255, 255, 255, 0.3)
  );
  z-index: 2;
  backdrop-filter: blur(1px);
  display: flex;

  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
`

const StyledColumn = styled.div`
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 80vh;
  /* text-align: center; */
  padding: 2rem;
`

const StyledRightsButton = styled.div`
margin-bottom: 1rem;
font-weight: bold;

`

export { GlassTileStyled, AppStyled, StyledColumn, StyledRightsButton }
