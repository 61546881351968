import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { StyledHeader, StyledFooter, StyledSection } from 'components/common/modal/ModalStyle'
import Input from 'components/common/input/Input'
import { editUser } from 'r_actions/users.actions'


const PasswordModal = ({ intl, setModal }) => {

  const dispatch = useDispatch()

  const { id } = useSelector(s => s.auth)

  const [submitted, setSubmitted] = useState(false)
  const [{ password, password_repeat }, setData] = useState({ password: '', password_repeat: '' })

  const validPassword = password.toString().length >= 6 
  const validRepeat = password === password_repeat

  const onChange = e => setData(d => ({ ...d, [e.target.name]: e.target.value })) 
  
  const onSubmit = () => {
    setSubmitted(true)

    if (validPassword && validRepeat) {
      dispatch(editUser({ id, editPackage: { password, state: 1 } }))
      setData({ password: '', password_repeat: '' })
      setModal(false)
    }

  }

  const onCancel = () => {
    setData({ password: '', password_repeat: '' })
    dispatch(editUser( { id, editPackage: { state: 1 } }))
    setModal(false)
  }

   
  return <div className="modal is-active">
    <div className="modal-background" />
    <div className="modal-card">

      <StyledHeader className="modal-card-head" style={{ padding: '20px' }}>
        {intl.formatMessage({ id: 'change_password' })}
      </StyledHeader>

      <StyledSection className="modal-card-body">
    
        <Input
          placeholder={intl.formatMessage({ id: 'password' })}
          type="password"
          value={password}
          isValid={!submitted || validPassword} 
          onChange={onChange}
          invalidText={ !!password 
            ? intl.formatMessage({ id: 'input-password_short' })
            : intl.formatMessage({ id: 'input-password' })
          } 
        /> 

        <Input
          placeholder={intl.formatMessage({ id: 'password_repeat' })}
          type="password"
          name="password_repeat"
          value={password_repeat}
          isValid={!submitted || validRepeat} 
          onChange={onChange}
          invalidText={ !!password_repeat 
            ? intl.formatMessage({ id: 'input-password_not_equal' })
            : intl.formatMessage({ id: 'input-password' })
          } 
        /> 



      </StyledSection>
      
      <StyledFooter className="modal-card-foot">

        <button className="button is-success" onClick={onSubmit}>
          {intl.formatMessage({ id: 'accept' })}
        </button>

        <button className="button" onClick={onCancel}>
          {intl.formatMessage({ id: 'cancel' })}
        </button>

      </StyledFooter>
    </div>
  </div>
}


PasswordModal.propTypes = {
  setModal: PropTypes.func,
}

export default injectIntl(PasswordModal)


