import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { setMode, setSelected } from 'r_actions/noApi.actions'

import { GlassViewer } from './Styles'

import { AdminsEditor, UsersEditor, BoxesEditor, ClientsEditor, ContactsEditor, 
  DiseasesEditor, PropertiesEditor, CategoryEditor, AdvisorEditor, RecipientsEditor }
  from 'components/_editors'

const EditorPanel = ({ path }) => {

  const dispatch = useDispatch()

  const { mode } = useSelector(s => s.noApi)

  const options = {
    admins:  <AdminsEditor />, 
    users:  <UsersEditor />, 
    clients:  <ClientsEditor />,
    contacts:  <ContactsEditor />,
    properties:  <PropertiesEditor />,
    boxes:  <BoxesEditor />,
    diseases:  <DiseasesEditor />,
    categories:  <CategoryEditor />,
    advisors:  <AdvisorEditor />,
    recipients:  <RecipientsEditor />,
  }

  const viewerStyle = mode === 'icon' 
    ?{ alignItems: 'center', justifyContent: 'center', display: 'flex' }
    : {}


  useEffect(() => {
    return () => {
      dispatch(setSelected(null))
      dispatch(setMode('icon'))
    }
  }, [dispatch])
  
  return <GlassViewer className="column is-two-thirds" style={{ ...viewerStyle }}>
    {options[path]}
  </GlassViewer>

}

EditorPanel.propTypes = {
  path: PropTypes.string,
}


export default EditorPanel