import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const Radio = ({ onChange, options, selected, property }) => {

  const { mode } = useSelector(s => s.noApi)

  const selectedOption = options.find(o => +o.value === +selected)

  return  mode === 'edit' 

    ? <div className="control p-3">

      { options.map(o => 
        <label className="radio" key={o.value}>
          <input className="m-2" type="radio" name={property} value={o.value} onChange={onChange} checked={+o.value === +selected}/>
          {o.title}
        </label>
      )}

    </div>

    : <div className="m-3">{selectedOption.title}</div>
       
}


Radio.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  property: PropTypes.string,
}

  
export default Radio