import { ADVISORS } from './feathers'

export const _fetchAdvisors = async () => {
  const { data } = await ADVISORS.find()
  return data
}

export const _createAdvisor = async (_advisor) => {
  const advisor = await ADVISORS.create(_advisor)
  return advisor
}

export const _editAdvisor = async ({ id, data }) => {

  const { file } = data

  const newData = { ...data, file: file 
    ? typeof file === 'string'
      ? null
      : file
    : 'remove'
  }

  const advisor = await ADVISORS.patch(id, newData)
  return advisor
}

export const _deleteAdvisor = async (id) => {
  const advisor = await ADVISORS.remove(id)
  return advisor
}


