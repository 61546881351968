import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createProperty, editProperty, deleteProperty } from 'r_actions/properties.actions'
import { setMode, setSelected } from 'r_actions/noApi.actions'
import PropertyEditorForm from './PropertyEditorForm'

const empty = {  id: null, title: null, areas: [], street: null, postcode: null, city: null }


const PropertyEditor = () => {

  const dispatch = useDispatch()

  const { selected: thisProperty } = useSelector(s => s.noApi)
  const properties = useSelector(s => s.properties || []) 
   
  const selected = thisProperty && properties.find(p => +p.id === +thisProperty.id)
    
  const [data, setData] = useState({})
  const [submitted, setSubmitted] = useState(false)
    
  const { id, title, street, postcode, city } = data

    
  const onChange = e => { 
    const { name, value, validity } = e.target     
    if (name === 'postcode' && validity.valid) return setData(d => ({ ...d, [name]: value }))
    if (name !== 'postcode') setData(d => ({ ...d, [name]: value })) 
  }
  
  useEffect(() => {
  
    selected && setData(d => ({ ...d, ...selected }))
    !selected && setData(d => ({ ...d, ...empty }))
  }, [selected])
  
  const cleanStuff = () => {
    setData(empty)
    dispatch(setMode('icon'))
    dispatch(setSelected(null))
    setSubmitted(false)
  }
 
  const onSubmit = () => {
    
    if(!!title && !!street && !!postcode && !!city ) {
      if(id) {
        dispatch(editProperty({ id, data }))
        setSubmitted(false)
        return dispatch(setMode('view'))
      }
      dispatch(createProperty(data))
      setSubmitted(false)
    }
    setSubmitted(true)
  }

  const onDelete = () => {
    dispatch(deleteProperty(id))
    cleanStuff()
  }

  return <PropertyEditorForm 
    submitted={submitted}
    data={data}
    onChange={onChange}
    onSubmit={onSubmit}
    onDelete={onDelete}
    setSubmitted={setSubmitted}
  />
    

}


export default PropertyEditor
