import React from 'react'
import PropTypes from 'prop-types'

const SearchField = ({ onSearch, search }) => (

  <div className="panel-block">
    <p className="control has-icons-left">
      <input
        className="input"
        type="text"
        placeholder="Search"
        value={search}
        onChange={onSearch}
      />
      <span className="icon is-left">
        <i className="fas fa-search" aria-hidden="true"></i>
      </span>
    </p>
  </div>
  
)

  

SearchField.propTypes = {
  search: PropTypes.string,
  onSearch: PropTypes.func,
}

export default SearchField
