import * as Sentry from '@sentry/browser'
import initialState from './initialState'
import toast from 'helpers/toast'
import { formatMessage } from 'intl/CurrentLocale.js'

export default function error(error = initialState.error, action) {

  const { error: err, type } = action

  if(err){
    const isDeleteType = type.includes('DELETE')
    const isDeleteMessage = err.message.includes('delete')

    const isCreateType = type.includes('CREATE')

    if(isDeleteType && isDeleteMessage) toast('error', formatMessage({ id: 'error_delete' }) )
    if(isCreateType) toast('error', formatMessage({ id: 'error_create' }) )
     
    Sentry.captureException(err)
       
  }
   
  return error
 
}


