import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createDisease, editDisease, deleteDisease } from 'r_actions/diseases.actions'
import { setMode, setSelected } from 'r_actions/noApi.actions'
import DiseaseEditorForm from './DiseaseEditorForm'

const empty = {  id: null, title: '' }


const DiseaseEditor = () => {

  const dispatch = useDispatch()

  const { selected } = useSelector(s => s.noApi)

  const [data, setData] = useState({})
  const [submitted, setSubmitted] = useState(false)
    
  const { id, title } = data

    
  const onChange = e => { 
    const { name, value } = e.target     
    setData(d => ({ ...d, [name]: value })) 
  }
  
  useEffect(() => {

    selected && setData(d => ({ ...d, ...selected }))
    !selected && setData(d => ({ ...d, ...empty }))
  }, [selected])
  
  const cleanStuff = () => {
    setData(empty)
    dispatch(setMode('icon'))
    dispatch(setSelected(null))
    setSubmitted(false)
  }
 
  const onSubmit = () => {
    setSubmitted(true)
    
    if(!!title) {
      id 
        ? dispatch(editDisease({ id, data }))
        : dispatch(createDisease(data))
      cleanStuff()
    }
  }

  const onDelete = () => {
    dispatch(deleteDisease(id))
    cleanStuff()
  }

  return <DiseaseEditorForm 
    submitted={submitted}
    data={data}
    onChange={onChange}
    onSubmit={onSubmit}
    onDelete={onDelete}
    setSubmitted={setSubmitted}
  />
    
}


export default DiseaseEditor
