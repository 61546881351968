import styled from 'styled-components'

const StyledPreview = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Image = styled.figure`
  padding: 0 3rem;
  padding-bottom: 3rem;
`

const StyledUploader = styled.div`
  margin-bottom: 3rem;
  .file-label {
    width: 100%;
  }
  .file-name {
    width: 100%;
  }
`

export { StyledPreview, StyledUploader, Image }
