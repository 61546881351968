import React from 'react'
import { PropTypes } from 'prop-types'
import { Navigation, Title, Button } from './Styles'
import { ChevronLeft, ChevronRight } from 'react-feather'


const PDFNavigation = ({ pages, activePage, onNavigate }) => {

  return (

    <Navigation className="columns" >
      
      { +activePage <= 1 

        ? <Title className="button is-primary column"/>
        : <Button className="button is-primary column" onClick={() => onNavigate()}>
          <ChevronLeft />
        </Button>

      }

      <Title className="button is-primary column">{`${activePage} / ${pages}`}</Title>

      {+activePage >= +pages 

        ? <Title className="button is-primary column"/>
        : <Button className="button is-primary column" onClick={() => onNavigate(1)}>
          <ChevronRight />
        </Button>
        
      }

    </Navigation> 
     
  )
}


PDFNavigation.propTypes = {
  pages: PropTypes.number,
  activePage: PropTypes.number,
  onNavigate: PropTypes.func,
}

export default PDFNavigation


