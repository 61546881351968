export const FETCH_DISEASES_SUCCESS = 'FETCH_DISEASES_SUCCESS'
export const FETCH_DISEASES_FAILURE = 'FETCH_DISEASES_FAILURE'

export const CREATE_DISEASES_SUCCESS = 'CREATE_DISEASES_SUCCESS'
export const CREATE_DISEASES_FAILURE = 'CREATE_DISEASES_FAILURE'

export const EDIT_DISEASES_SUCCESS = 'EDIT_DISEASES_SUCCESS'
export const EDIT_DISEASES_FAILURE = 'EDIT_DISEASES_FAILURE'

export const DELETE_DISEASES_SUCCESS = 'DELETE_DISEASES_SUCCESS'
export const DELETE_DISEASES_FAILURE = 'DELETE_DISEASES_FAILURE'

