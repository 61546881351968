import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { User } from 'react-feather'
import EditorButtons from 'components/WorkSpace/editorButtons/EditorButtons'
import { StyledColumn } from 'Styles'

import Input from 'components/common/input/Input'
import Dropdown from 'components/common/dropdown/Dropdown' 
import Radio from 'components/common/radio/Radio'


const CLIENT = 2


const AdminsEditorForm = ({ intl, data, onChange, checkName, onSubmit, onDelete, valid, setSubmitted, submitted, clients }) => {

  const { id, username, mail, name, surname, role, client, phone } = data
  
  const { mode } = useSelector(s => s.noApi)
  const { id: myId, role: myRole } = useSelector(s => s.auth)
  const validUsername = useSelector(s => s.validUsername)

  const selectedClient = (client && clients.find(c => +c.id === +client)) || {}

  const viewOnly = mode === 'view' ? true : false


  const radioOptions = [
    { title: intl.formatMessage({ id: 'admins_jam_admin' }), value: 1 },
    { title: intl.formatMessage({ id: 'admins_client_admin' }), value: 2 },
  ]

  return mode === 'icon' 

    ? <User size={48} color="#949494"/>

    : <div className="columns">

      <StyledColumn className="column">

        {( mode === 'edit' && !id ) 
          ? <>

            { +myRole !== CLIENT &&
              <Radio onChange={onChange} options={radioOptions} selected={role} property="role"/> 
            }

            { (+role === CLIENT && myRole !== CLIENT && !viewOnly) &&
           <Dropdown
             options={clients}
             property="title"
             type="client"
             value={client}
             onChange={onChange}
             placeholder = {intl.formatMessage({ id: 'admins_assign_client' })}
           />
            }

          </>
          : +role === CLIENT && <Input
            placeholder={intl.formatMessage({ id: 'admins_assigned_client' })}
            type="client"
            value={selectedClient.title}
            isValid={true} 
            onChange={() => {}}
            viewOnly={true}
        
          />   
        }
         
        <Input
          placeholder={intl.formatMessage({ id: 'username' })}
          type="username"
          value={username}
          isValid={!submitted || valid.username} 
          onChange={onChange}
          invalidText={validUsername 
            ? intl.formatMessage({ id: 'input-username' })
            : intl.formatMessage({ id: 'input-username_taken' })
          }
          viewOnly={viewOnly}
          onBlur={() => checkName(selectedClient && selectedClient.clientNumber)}
        
        />   


        <Input
          placeholder={intl.formatMessage({ id: 'mail' })}
          type="mail"
          value={mail}
          isValid={!submitted || valid.mail} 
          onChange={onChange}
          invalidText={ !!mail
            ? intl.formatMessage({ id: 'input-mail_invalid' })
            : intl.formatMessage({ id: 'input-mail' })
          }
          viewOnly={viewOnly}
        
        />   

        <Input
          placeholder={intl.formatMessage({ id: 'users_phone' })}
          type="phone"
          value={phone}
          isValid={true} 
          onChange={onChange}
          viewOnly={viewOnly}
        
        />   

        <Input
          placeholder={intl.formatMessage({ id: 'name' })}
          type="name"
          value={name}
          isValid={!submitted || valid.name} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'input-name' })}
          viewOnly={viewOnly}
        
        />  

        <Input
          placeholder={intl.formatMessage({ id: 'surname' })}
          type="surname"
          value={surname}
          isValid={!submitted || valid.surname} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'input-surname' })}
          viewOnly={viewOnly}
        
        />   

      </StyledColumn>

      <StyledColumn className="column"/>

      <EditorButtons onSubmit={onSubmit} canDelete={myId !== id} onDelete={onDelete} reset={() => setSubmitted(false)}/>
    </div>

  
}

AdminsEditorForm.propTypes = {
  data: PropTypes.object, 
  onChange: PropTypes.func, 
  checkName: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  valid: PropTypes.object,
  setSubmitted: PropTypes.func,
  clients: PropTypes.array,
}



export default injectIntl(AdminsEditorForm)
