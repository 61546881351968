import React , { useEffect }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionHandler from 'components/WorkSpace/SectionHandler'
import { fetchProperties } from 'r_actions/properties.actions'

const Properties = () => {
  const dispatch = useDispatch()

  const properties = useSelector(s => s.properties) || []

  useEffect(() => {
    dispatch(fetchProperties())
  }, [dispatch])

  return properties && <SectionHandler list={properties} />
}

Properties.propTypes = {}

export default Properties
