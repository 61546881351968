import styled from 'styled-components'

const StyledContainer = styled.article`
user-select: none;
overflow: hidden;

`

const StyledScrollable= styled.div`
max-height: 35rem;
overflow-y: auto;
`

const StyledElement= styled.div`
margin: 0 !important;
padding: 0 1rem;
&:hover{
    cursor: pointer;
    background-color: #ededed;
}
`


export { StyledContainer, StyledScrollable, StyledElement }
