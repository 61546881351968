import { LOCALES } from '../locales'

export default {
  [LOCALES.ENGLISH]: {




  }
    
}