import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { createAdvisor, editAdvisor, deleteAdvisor } from 'r_actions/advisor.actions'
import { setMode, setSelected } from 'r_actions/noApi.actions'
import AdvisorEditorForm from './AdvisorEditorForm'
import toast from 'helpers/toast'

const empty = {  id: null, title: null, content: '', file: null }


const AdvisorEditor = ({ intl }) => {

  const dispatch = useDispatch()

  const { selected } = useSelector(s => s.noApi)

  const [data, setData] = useState({})
  const [submitted, setSubmitted] = useState(false)
    
  const { id, title, content, file } = data


  const checkOnlyOne = (name, value) => {
    const isPDF = name === 'file' && (value && value.type === 'pdf')
    const hasPDF = !!file && (typeof file === 'string'
      ? file.substring(file.lastIndexOf('.') + 1) === 'pdf'
      : file.mimetype === 'application/pdf'
    )
   
    if ((hasPDF && name === 'content') || (!!content && isPDF)) {
      toast('error', intl.formatMessage({ id: 'advisors_select_only_one' }))
      return false
    }
    return true
  }
    
  const onChange = e => { 
    const { name, value } = e.target     
    checkOnlyOne(name, value) && setData(d => ({ ...d, [name]: value })) 
    dispatch(setSelected({ ...selected, [name]: value }))
  }
  
  useEffect(() => {
    selected && setData(d => ({ ...d, ...selected }))
    !selected && setData(d => ({ ...d, ...empty, file }))
  }, [selected, file])
  
  const cleanStuff = () => {
    setData(empty)
    dispatch(setMode('icon'))
    dispatch(setSelected(null))
    setSubmitted(false)
  }
 
  const onSubmit = () => {
    setSubmitted(true)
    
    if(!!title && (!!file || !!content)) {
      id 
        ? dispatch(editAdvisor({ id, data: { ...data, file } }))
        : dispatch(createAdvisor(data))
      cleanStuff()
    }
  }

  const onDelete = () => {
    dispatch(deleteAdvisor(id))
    cleanStuff()
  }


  return <AdvisorEditorForm 
    submitted={submitted}
    data={data}
    onChange={onChange}
    onSubmit={onSubmit}
    onDelete={onDelete}
    setSubmitted={setSubmitted}
  />
    

}


export default injectIntl(AdvisorEditor)
