import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Home } from 'react-feather'
import { StyledColumn } from 'Styles'
import EditorButtons from 'components/WorkSpace/editorButtons/EditorButtons'
import Areas from './areas/Areas'
import AreasEditor from './areas/AreasEditor'

import Input from 'components/common/input/Input'
// import Dropdown from 'components/common/Dropdown' //TODO: preselector

const PropertyEditorForm = ({ intl, submitted, data, onChange, onSubmit, onDelete, setSubmitted }) => {

  const { title, postcode, city, street, areas, id } = data

  const { selected } = useSelector(s => s.noApi)
  const [selectedArea, setSelectedArea] = useState(null)

  useEffect(() => setSelectedArea(null), [selected])

  const { mode } = useSelector(s => s.noApi)

  const viewOnly = mode === 'view' ? true : false

  const onCancel = () => {
    setSelectedArea(null)
    setSubmitted(false)
  }


  const selectedOrNew = selectedArea 
    ? selectedArea === 'new'
      ? { title: '', subAreas: [] }
      : areas.find(a => +a.id === +selectedArea)
    : null

  return mode === 'icon'  

    ? <Home size={48} color="#949494"/>

    : <div className="columns">

      <StyledColumn className="column">

        <Input
          placeholder={intl.formatMessage({ id: 'properties_title' })}
          type="title"
          value={title}
          isValid={!!title || !submitted} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'properties_title_missing' })}
          viewOnly={viewOnly}
        
        /> 

        { id &&   
            <Areas 
              areas={areas} 
              selectArea={setSelectedArea}
              selectedArea={selectedArea}
              viewOnly={viewOnly}
            /> 
        }

        <Input
          placeholder={intl.formatMessage({ id: 'properties_street' })}
          type="street"
          value={street}
          isValid={!!street || !submitted} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'properties_street_missing' })}
          viewOnly={viewOnly}
        />  

        <Input
          placeholder={intl.formatMessage({ id: 'properties_postcode' })}
          type="postcode"
          pattern="^-?[0-9]\d*\.?\d*$"
          value={postcode}
          isValid={(!!postcode && postcode.toString().length >= 5) || !submitted} 
          onChange={onChange}
          invalidText={!postcode
            ? intl.formatMessage({ id: 'properties_postcode_missing' })
            : intl.formatMessage({ id: 'properties_postcode_short' })
          }
          viewOnly={viewOnly}
        /> 

        <Input
          placeholder={intl.formatMessage({ id: 'properties_city' })}
          type="city"
          value={city}
          isValid={!!city || !submitted} 
          onChange={onChange}selectedOrNew
          invalidText={intl.formatMessage({ id: 'properties_city_missing' })}
          viewOnly={viewOnly}
        /> 

            
      </StyledColumn>


      <StyledColumn className="column">

        { 
          selectedArea && 
         <AreasEditor 
           selected={selectedOrNew} 
           selectArea={setSelectedArea}
           viewOnly={viewOnly}
         />
        }
       
      </StyledColumn>

      <EditorButtons onSubmit={onSubmit} canDelete={true} onDelete={onDelete} reset={onCancel}/>
    </div>

  
}

PropertyEditorForm.propTypes = {
  submitted: PropTypes.bool,
  data: PropTypes.object, 
  onChange: PropTypes.func, 
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  valid: PropTypes.object,
  setSubmitted: PropTypes.func,
}



export default injectIntl(PropertyEditorForm)
