import React from 'react'
import PropTypes from 'prop-types'
import { StyledOrdersBar, StyledColumn } from '../Styles'
import { ChevronDown, ChevronUp, AlertTriangle } from 'react-feather'
import { injectIntl } from 'react-intl'

const PRESCRIPTION = 3
const CAREBOX = 5

const OrderBar = ({ intl, order, open }) => {

  const { userObject, patientObject, date, categoryObject, therapyObject, docs, category } = order

  const { name, surname } = userObject
  const { name: nameP, surname: surnameP } = patientObject

  const showAlert = !docs.length || (+category !== CAREBOX && !docs.find(d => +d.type === PRESCRIPTION))

  return  <StyledOrdersBar className="columns">

    <div className="column is-1" style={{ textAlign: 'center' }}> 
      {showAlert&& <AlertTriangle color={'#ca7272'} data-tip={intl.formatMessage({ id: 'orders_missing_docs' })}/>}
    </div>

    <div className="column">
      {date}
    </div>

    <StyledColumn className="column">
      {categoryObject.title}
    </StyledColumn>

    <StyledColumn className="column" >
      {therapyObject.title || '-'}
    </StyledColumn>

    <StyledColumn className="column" >
      {`${name} ${surname}`}
    </StyledColumn>

    <StyledColumn className="column">
      {`${nameP} ${surnameP}`}
    </StyledColumn>

    <div className="column is-1">
      {
        open
          ? <ChevronUp/>
          : <ChevronDown/>
      }
    </div>
  </StyledOrdersBar>
    
}

OrderBar.propTypes = {
  order: PropTypes.object, 
  open: PropTypes.bool,

}


export default injectIntl(OrderBar)


