import { useSelector } from 'react-redux'

import routesAdmin from './routesAdmin'
import routesJam from './routesJam'

const JAM = 1

const Redirector = () => {

  const { role } = useSelector(s => s.auth)


  return +role === JAM
    ? routesJam
    : routesAdmin
}
 

export default Redirector