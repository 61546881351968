export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS'
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE'

export const CREATE_CLIENTS_SUCCESS = 'CREATE_CLIENTS_SUCCESS'
export const CREATE_CLIENTS_FAILURE = 'CREATE_CLIENTS_FAILURE'

export const EDIT_CLIENTS_SUCCESS = 'EDIT_CLIENTS_SUCCESS'
export const EDIT_CLIENTS_FAILURE = 'EDIT_CLIENTS_FAILURE'

export const DELETE_CLIENTS_SUCCESS = 'DELETE_CLIENTS_SUCCESS'
export const DELETE_CLIENTS_FAILURE = 'DELETE_CLIENTS_FAILURE'

