import { combineReducers } from 'redux'

import auth  from './auth.reducer'
import users from './users.reducer'
import admins from './admins.reducer'
import clients from './clients.reducer'
import noApi from './noApi.reducer'
import validUsername from './username.reducer'
import contacts from './contacts.reducer'
import properties from './properties.reducer'
import boxes from './boxes.reducer'
import diseases from './diseases.reducer'
import orders from './orders.reducer'
import patients from './patients.reducer'
import categories from './categories.reducer'
import advisors from './advisor.reducer'
import recipients from './recipient.reducer'
import error from './error.reducer'

import { LOGOUT_SUCCESS } from 'r_constants/auth.constants'
import initialState from './initialState'


const appReducer = combineReducers({
  auth,
  users,
  admins,
  clients,
  noApi,
  validUsername,
  contacts,
  properties,
  boxes,
  diseases,
  orders,
  patients,
  categories,
  advisors,
  recipients,
  error
  
})


const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    sessionStorage.clear()
    state = initialState 
  }
  return appReducer(state, action)
}

export default rootReducer