import React from 'react'
import PropTypes from 'prop-types'
import { PlusCircle } from 'react-feather'
import { injectIntl } from 'react-intl'
import { StyledPanel, StyledScrollBlock, StyledElement } from '../Styles'

//TODO: new styled for no areas message


const Areas = ({ intl, areas, selectArea, selectedArea, viewOnly }) => {

  const active = { backgroundColor: '#2e2d2d3d' }

  return <StyledPanel className="panel is-primary">

    <p className="panel-heading">
      {intl.formatMessage({ id: 'properties_areas' })}
    </p>

    <StyledScrollBlock>
      {
        areas && areas.length
          ? areas.map(a => 
            <StyledElement 
              key={a.id} 
              className="panel-block" 
              onClick={() => selectArea(a.id)}
              style={+a.id === +selectedArea ? { ...active } : {}}
            >
              {a.title}
            </StyledElement>
          )
          : <StyledElement> 
            {intl.formatMessage({ id: 'properties_areas_none' })}
          </StyledElement>
      }
     
    </StyledScrollBlock>

    { !viewOnly && 

       <button className="button is-primary" onClick={() => selectArea('new')} >
         <PlusCircle size={20} color="#fff"/>
       </button>

    }
  
  </StyledPanel>
}

Areas.propTypes = {
  areas: PropTypes.array,
  selectArea: PropTypes.func,
  selectedArea: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]), 
  viewOnly: PropTypes.bool,
}

export default injectIntl(Areas)
