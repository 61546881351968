import React , { useEffect }from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionHandler from 'components/WorkSpace/SectionHandler'
import { fetchClients } from 'r_actions/clients.actions'

const Clients = () => {
  const dispatch = useDispatch()

  const clients = useSelector(s => s.clients) || []

  useEffect(() => {
    dispatch(fetchClients())
  }, [dispatch])

  return clients && <SectionHandler list={clients} />
}


export default Clients
