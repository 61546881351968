import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionHandler from 'components/WorkSpace/SectionHandler'
import { fetchContacts } from 'r_actions/contacts.actions'

const Contacts = () => {
  const dispatch = useDispatch()

  const contacts = useSelector(s => s.contacts) || []

  useEffect(() => {
    dispatch(fetchContacts())
  }, [dispatch])


  return (
    contacts && (

      <SectionHandler
        list={contacts}
      />
      
    )
  )
}
//TODO: loading

Contacts.propTypes = {}

export default Contacts
