import styled from 'styled-components'
import { animated } from 'react-spring'

const StyledWrapper = styled.div`
  margin-top: 3rem;
  position: relative;
  width: 100%;
  max-height: 93vh;
  min-height: 93vh;
  overflow: hidden;
`

const StyledHeader = styled.div`
  border-radius: 1.5rem;
  background: #0a0a0ab3;
  color: white;
  padding-left: 3rem;
  margin: 10px;
`

const StyledList = styled.div`
  overflow-y: hidden;
  padding-bottom: 1rem;
`

const StyledOrdersBar = styled.div`
  margin: 0;
  padding: 0;
`

const StyledInfoWrapper = styled.div`
  background: white;
  border-radius: 1.5rem;
  margin: 10px 20px;
  padding: 10px;
  user-select: none;
  cursor: pointer;
`

const StyledInfoPanel = styled(animated.div)`
  will-change: transform, opacity, height, visibility;
  padding-bottom: 10px;
  height: 0px;
`

const StyledColumn = styled.div`
  text-transform: capitalize;
`

const StyledSemiLink = styled.div`
color: #3578acdb;
  &:hover{
    color: black
  }
`

export {
  StyledWrapper,
  StyledHeader,
  StyledOrdersBar,
  StyledInfoPanel,
  StyledList,
  StyledInfoWrapper,
  StyledColumn,
  StyledSemiLink,
}
