import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Smile } from 'react-feather'
import { StyledColumn } from 'Styles'
import ColorSelector from 'components/common/colorSelector/ColorSelector'
import EditorButtons from 'components/WorkSpace/editorButtons/EditorButtons'

import Input from 'components/common/input/Input'
import FileUpload from 'components/common/upload/Upload' 
// import Dropdown from 'components/common/Dropdown' //TODO: preselector

const ClientEditorForm = ({ intl, submitted, data, onChange, onSubmit, onDelete, setSubmitted }) => {

  const { title, password, primaryColor, secondaryColor, logo } = data

  const { mode } = useSelector(s => s.noApi)

  const viewOnly = mode === 'view' ? true : false

  return mode === 'icon'  

    ? <Smile size={48} color="#949494"/>

    : <div className="columns">

      <StyledColumn className="column">

        <Input
          placeholder={intl.formatMessage({ id: 'name_description' })}
          type="title"
          value={title}
          isValid={!!title || !submitted} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'name_description_empty' })}
          viewOnly={viewOnly}
        
        />  

        <Input
          placeholder={intl.formatMessage({ id: 'client_default_password' })}
          type="_password"
          value={password}
          name="password"
          isValid={!!password || !submitted} 
          onChange={onChange}
          invalidText={intl.formatMessage({ id: 'client_default_password_enter' })}
          viewOnly={viewOnly}
        
        />  

        <FileUpload
          label={intl.formatMessage({ id: 'select_logo' })} 
          file={logo}
          onChange={onChange}
          viewOnly={viewOnly}
          property="logo"
        />
    
      </StyledColumn>

      <StyledColumn className="column">

        <ColorSelector
          placeholder={intl.formatMessage({ id: 'primary_color' })}
          type="primaryColor"
          value={primaryColor}
          onChange={onChange}
          viewOnly={viewOnly}
        />  

        <ColorSelector
          placeholder={intl.formatMessage({ id: 'secondary_color' })}
          type="secondaryColor"
          value={secondaryColor}
          onChange={onChange}
          viewOnly={viewOnly}
        />  

      </StyledColumn>

      <EditorButtons onSubmit={onSubmit} canDelete={true} onDelete={onDelete} reset={() => setSubmitted(false)}/>
    </div>

  
}

ClientEditorForm.propTypes = {
  submitted: PropTypes.bool,
  data: PropTypes.object, 
  onChange: PropTypes.func, 
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  valid: PropTypes.object,
  setSubmitted: PropTypes.func,
}



export default injectIntl(ClientEditorForm)
