import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'

const toast = (type, text, callback) => {

  const types = {
    success: '#00b09b, #96c93d', 
    error: '#be3039, #fc4567', 
    information: '#309de3, #96c93d'
  }

  Toastify({
    text,
    duration: 3000,
    // destination: 'https://github.com/apvarun/toastify-js',
    // newWindow: true,
    close: false,
    gravity: 'bottom', // `top` or `bottom`
    position: 'left', // `left`, `center` or `right`
    backgroundColor: `linear-gradient(to right, ${types[type]})`,
    stopOnFocus: true, // Prevents dismissing of toast on hover
    onClick: () => callback() // Callback after click
  }).showToast()


}



export default toast


