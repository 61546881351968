import React from 'react'
import PropTypes from 'prop-types'

const Input = ({ placeholder, value, name, isValid, onChange, invalidText, viewOnly, maxLength=null, rows=20  }) => {

  const style = {
    pointerEvents: viewOnly ? 'none' : 'all',
  }



  return (    
    <div className="field">

      <textarea 
        className="textarea" 
        name={name}
        value={value} 
        placeholder={placeholder} 
        onChange={onChange}
        style={{ ...style }}  
        maxLength={maxLength}
        rows={rows}

        
      />

      {value && <p className="help" 
        style={{ 
          color: value.toString().length === maxLength
            ? 'red'
            : '#4a4a4a'
        }}>
        {placeholder}
      </p>
      }
      {!isValid && <p className="help is-danger">{invalidText}</p>}

    </div>
  )
   
}


Input.propTypes = {
  placeholder: PropTypes.string, 
  name: PropTypes.string,
  value: PropTypes.string,
  isValid: PropTypes.bool, 
  onChange: PropTypes.func, 
  invalidText: PropTypes.string, 
  viewOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  rows: PropTypes.number,
}

  
export default Input